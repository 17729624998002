// import '../public/style.css'
import './static/fonts/fonts.css'
// import './static/style.css'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router} from 'react-router-dom'

import store from './store'

import App from './app'

render(
    <Provider store={store}>
        <Router >
          <App />
        </Router>
    </Provider>,
    document.getElementById('app')
)
