import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
// import Card from '@mui/material/Card'
// import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import axios from 'axios'


let debug = localStorage.getItem("debuggerMode") === 'true'

const ThankYou = (props) => {
  if(debug) console.log("ThankYou PROPS: ", props)
  const {
    // & Redux state PROPS
    // theUser,
    // & passed in as PROPS

  } = props

  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)
  
  const sessionId = query.get('session_id')
  const billingFrequency = query.get('billingFrequency')


  useEffect(() => {
    if (sessionId) {
      // ******   TRACKING Events | FB & Mixpanel     ******
      try{
        window.mixpanel.track("Purchase")
        window.ReactPixel.track("Purchase", {currency: "USD", value: 14.99})
        window.ReactPixel.track("StartTrial")
      }
      catch (error) {
        if(debug) console.log("error ==> ", error)
      }
  
      if(debug) console.log("Thank you page reached...creating internal UserSub ==> ")
        
      // !! TODO Don't use localStorage!!! Run migration to keep sessionId in database and make it unique (and allow null)
      let latestPaymentSessionId = localStorage.getItem('latestPaymentSessionId')
      
      // ? Check if this session has already created a userSub
      if(!latestPaymentSessionId || sessionId !== latestPaymentSessionId) {
        if(debug) console.log('New session, create userSub')
        
        axios.post('/api/services/stripe/create-usersub-from-session', {
          sessionId,
          billingFrequency})
          .then(() => {
            if(debug) console.log('UserSub created successfully')
            localStorage.setItem('latestPaymentSessionId', sessionId)
          })
          .catch(error => {
            if(debug) console.error('Error creating UserSub:', error)
          })
      }
      else {
        if(debug) console.log('This session has already been used to create a userSub')
      }
    }
    else {
      navigate('/') // Redirect to home if no session ID
    }
  }, [sessionId, navigate])


  return (
    <Container maxWidth="md" component="main">
    <Box sx={{ padding: 5, marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>      
      <Typography
        component="h4"
        variant="h4"
        align="center"
        color="text.primary"
        gutterBottom
      >
        You are now subscribed to AIMme! 🙌
      </Typography>
      <Typography variant="h5" align="center" color="text.secondary">
        Continue to the <a href="/chat">track page →</a>
      </Typography>
    </Box>
    </Container>
  )
};

export default ThankYou;
