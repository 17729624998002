import { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import StarIcon from '@mui/icons-material/StarBorder'
import Typography from '@mui/material/Typography'
// import Link from '@mui/material/Link'
import Container from '@mui/material/Container'

import { getSubscriptions } from '../../redux/subscriptions'

import { matchSubsToTiers, handleStartStripeCheckout } from '../../utils/subscribe'

let debug = localStorage.getItem("debuggerMode") === 'true'


const Product = (props) => {
  if(debug) console.log("Product PROPS: ", props)
  const {
    // & Redux PROPS

    // & passed in as PROPS

  } = props

  const [products, setProducts] = useState([])
  const [selection, setSelection] = useState({})

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const subscriptions = useSelector(state => state.subscriptions.all)
  const latestUserSub = useSelector(state => state.theUser.userSubs.current)
  const userEmail = useSelector(state => state.theUser.email)


  useEffect(() => {
    dispatch(getSubscriptions())
  }, [])
  
  /* // ! TODO: UPDATE.... OG reason for useEffect: 
      ? Check if user has an active subscription. If so, redirect back to main chat.
      & NEW REASON??? --> Since latestUserSub isActive... SHOW current subscription deeets?
  */
  useEffect(() => {
    if(latestUserSub?.endDate && latestUserSub?.subscription) {
      let userSubEndDateTime = DateTime.fromISO(latestUserSub.endDate)
      // if(debug) console.log("userSubEndDateTime ==> ", userSubEndDateTime)
      // if(debug) console.log("DateTime.now() ==> ", DateTime.now())
      let isActive = userSubEndDateTime > DateTime.now()
      if(debug) console.log("Checking if latestUserSub isActive...  ==> ", isActive)

      if(isActive) {
        if(debug) console.log("Since latestUserSub isActive... SHOW current subscription deeets?")
        if(debug) console.log(`Compare latestUserSub.subscription.stripePriceId (${latestUserSub.subscription.stripePriceId}) to products (search array for matching stripePriceId)... products ==> `, products)

        if(products) {
          products.map((product) => {
            if(debug) console.log("Checking for product.stripePriceId ==> ", product.stripePriceId)
            // console.log("that matches latestUserSub.subscription.stripePriceId ==> ", latestUserSub.subscription.stripePriceId)

            if(product.stripePriceId === latestUserSub.subscription.stripePriceId) {
              if(debug) console.log("FOUND the matching product! product ==> ", product)
            }
          })
        }
        // navigate('/chat')
        // navigate('/subscription')
      }
    }
  }, [latestUserSub, products])
  
  useEffect(() => {
    if(subscriptions) {
      let availProducts = matchSubsToTiers(subscriptions)
      if(debug) console.log("useEffect()... availProducts ==> ", availProducts)
      setProducts(availProducts)
    }
  }, [subscriptions])

  const query = new URLSearchParams(location.search)
  const maxed = query.get('maxed')
  const displayMaxMessage = maxed === '1'


  return (
      <Container maxWidth="md" component="main">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography
            component="h2"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Subscribe to AIMme
          </Typography>

          {displayMaxMessage && (
            <Typography variant="h5" align="center" color="text.secondary" component="p">
                You've used all of your free images.
            </Typography>
          )}

          <Grid container sx={{ marginTop: 2 }} spacing={5} justifyContent="center">
            {products.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={6}
                md={6}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      // backgroundColor: "neutral.light"
                      // backgroundColor: "primary.light"
                      backgroundColor: "secondary.light"
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        {tier.monthlyPrice}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box>
                    <Typography component="ul" sx={{ padding: 0}}>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                          sx={{ margin: 0, padding: 0, listStyle: 'none' }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {(latestUserSub?.endDate && (DateTime.fromISO(latestUserSub.endDate) > DateTime.now())) && (tier?.stripePriceId === latestUserSub?.subscription?.stripePriceId) ? (
                      <Button fullWidth variant='contained'
                        sx={{backgroundColor: "secondary.main", color: 'secondary.contrastText'}}
                        onClick={() => navigate('/subscription')}
                        >
                        Current Plan
                      </Button>
                    ) : (
                      <Button fullWidth 
                        variant={tier.buttonVariant}
                        onClick={() => {
                          let selected = {
                            // tier.monthlyPrice
                            stripePriceId: tier.stripePriceId,
                            billingFrequency: 'trial',
                            selectedPrice: tier.monthlyPrice,
                          }
                          handleStartStripeCheckout(selected, setSelection, userEmail, navigate)
                        }}
                        >
                        {tier.buttonText}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
  )
}

export default Product
