import axios from 'axios'
// import { DateTime } from 'luxon'

import { getUpdatedAll } from '../utils/getUpdatedAll'
// import { evalDateFormat } from '../utils/evalDateFormat'
// import { extractMealDates } from '../utils/calendar'
import { parseFoodsData } from '../utils/nutrition'

import { uploadedMedia } from './theUser'

const dev = process.env.NODE_ENV === 'development'

export const checkTaskStatus = (taskId) => {
  return new Promise((resolve, reject) => {
    const checkStatus = async () => {
      try {
        // ! TODO: send user's date with timezone?
        // const response = await axios.get(`/api/services/nutritionai/get-task-results/${taskId}`, { params: { userTodayDate : DateTime.now().toISO({ includeOffset: true }) } })
        const response = await axios.get(`/api/services/nutritionai/get-task-results/${taskId}`)
        const data = response.data

        if (response && data) {
          if (response.status === 201 && data.calories !== undefined) {
            console.log("Task is complete! data ==> ", data)
            resolve(data)
          }
          // else if (data.status === 'failed') {
          else if (response.status === 400 || response.status === 418) {
            console.log("Task failed! How to handle?")
            reject('Task failed')
          }
          else {
            // If not complete, check again after a delay
            setTimeout(checkStatus, 3000) // Check every 3 seconds
          }
        }
      }
      catch (error) {
        console.error('Error checking task status:', error)
        reject(error)
      }
    }

    checkStatus()
  })
}


// *** ACTION TYPES
const GOT_MEAL = 'GOT_MEAL'
const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
const UPDATED_MEAL = 'UPDATED_MEAL'
const GOT_TASK_ID = 'GOT_TASK_ID'
const EXTRACTED_FOOD_TEXT = 'EXTRACTED_FOOD_TEXT'
const EXTRACTED_FOOD_IMAGE = 'EXTRACTED_FOOD_IMAGE'
const GOT_MEAL_COUNT_BY_DAY = 'GOT_MEAL_COUNT_BY_DAY'
const GOT_MEAL_COUNT_BY_TYPE = 'GOT_MEAL_COUNT_BY_TYPE'
const GOT_MEALS = 'GOT_MEALS'
import { LOGGED_OUT } from './theUser'
const SEARCHED_FOOD_ITEMS = 'SEARCHED_FOOD_ITEMS'
const SELECTED_FOOD_ITEM = 'SELECTED_FOOD_ITEM'

// *** ACTION CREATORS
export const setLoadingStatus = (loading)  => ({
  type: SET_LOADING_STATUS,
  loading
})
export const gotMeal = (meal) => ({
  type: GOT_MEAL,
  meal
})
export const updatedMeal = (meal) => ({
  type: UPDATED_MEAL,
  meal
})
export const gotMealCountByDay = (mealCount) => ({
  type: GOT_MEAL_COUNT_BY_DAY,
  mealCount
})
export const gotMealCountByType = (mealCount) => ({
  type: GOT_MEAL_COUNT_BY_TYPE,
  mealCount
})
export const gotMeals = (meals) => ({
  type: GOT_MEALS,
  meals
})

export const gotTaskId = (taskId) => ({
  type: GOT_TASK_ID,
  taskId
})
export const extractedFoodText = (meal) => ({
  type: EXTRACTED_FOOD_TEXT,
  meal
})
export const extractedFoodImage = (meal) => ({
  type: EXTRACTED_FOOD_IMAGE,
  meal
})
export const searchedFoodItems = (foodItems) => ({
  type: SEARCHED_FOOD_ITEMS,
  foodItems,
})
export const selectedFoodItem = (foodItem) => ({
  type: SELECTED_FOOD_ITEM,
  foodItem,
})


// *** THUNK CREATORS
export const getMeal = (mealId) => {
  return async (dispatch) => {
    if(dev) console.log("getMeal THUNK => mealId: ", mealId)

    try {
      dispatch(setLoadingStatus(true))
      const { data } = await axios.get(`/api/meals/${mealId}`)

      if (data) {
        if(dev) console.log("getMeal THUNK => if (data) : ", data)
        if(data.foodComponents && typeof data.foodComponents === 'string') {
          data.foodComponents = JSON.parse(data.foodComponents)
        }
        if(data.foods && typeof data.foods === 'string') {
          data.foods = JSON.parse(data.foods)
        }
        // data = parseFoodsData(data)
        dispatch(gotMeal(data))
      }
    }
    catch (error) {
      dispatch(gotMeal({}))
      console.error('Whoops, trouble getting meal!', error)
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}
export const updateMeal = (updateType, mealId, theUpdate, navigate) => {
  return async (dispatch) => {
    if(dev) console.log(`updateMeal THUNK => (mealId: ${mealId}) theUpdate: `, theUpdate)

    try {
      dispatch(setLoadingStatus(true))
      const {data} = await axios.patch(`/api/meals/${mealId}`, theUpdate)

      if (data) {
        if(updateType === 'save') {
          dispatch(updatedMeal(data))
          navigate('/home')
        }
        else if(updateType === 'archive') {
          dispatch(updatedMeal(data))
          
          if('foods' in theUpdate) {
            console.log("Special ARCHIVE method... discarded isNewMeal straight from AI...")
            navigate('/chat')
          }
        }
        else dispatch(gotMeal(data))
      }
      console.log("The TRY bit of data: ", data)
    }
    catch (error) {
      // dispatch(gotMeal({}))
      console.error('Whoops, trouble updating meal!', error)
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}
export const getMeals = (dateTime, interval) => {
  return async (dispatch) => {
    if(dev) console.log("getMeals THUNK => dateTime: ", dateTime)
    if(dev) console.log("getMeals THUNK => interval: ", interval)

    try {
      dispatch(setLoadingStatus(true))

      // const { data } = await axios.get(`/api/meals/day/${dateTime}`)
      // const { data } = await axios.get(`/api/meals/${interval}/${intervalValue}/${dateTime}`)
      const { data } = await axios.get(`/api/meals/${interval}/${dateTime}`)

      if (data) {
        // if(dev) console.log("gotMeals THUNK => if (data) : ", data)
        let parsedData = data.map((item) => {
          console.log("parsedData... item: ", item)

          // return {...item, ...parseFoodsData(item)}
          return {...parseFoodsData(item)}
        })
        
        if(dev) console.log("gotMeals THUNK => if (parsedData) : ", parsedData)
        dispatch(gotMeals(parsedData))
      }
      else {
        if(dev) console.log("gotMeals THUNK => else (data) : ", data)
      }
    }
    catch (error) {
      // dispatch(gotMeals({}))
      console.error(`Whoops, trouble getting meals${interval ? ' by ' + interval: ''}!`, error)
      // dispatch(gotMeals(error))
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}
export const getMealCountByDay = (dateTime, interval) => {
  return async (dispatch) => {
    if(dev) console.log("getMealCountByDay THUNK => dateTime: ", dateTime)
    if(dev) console.log("getMealCountByDay THUNK => interval: ", interval)

    try {
      dispatch(setLoadingStatus(true))

      // let dateFormat = evalDateFormat(date)
      // const { data } = await axios.get(`/api/meals/month/${dateFormat === 'isoTime' ? date : ''}`, {
      //   ...(!dateFormat === 'isoWithTimeZone' && { params: {dateTime: date } }),
      // })
      const { data } = await axios.get(`/api/meals/meal-count/${interval}/${dateTime}`)

      if (data) {
        dispatch(gotMealCountByDay({ 
          mealCount: {
            interval: interval,
            data: data 
          }
        }))
        if(dev) console.log("gotMealCountByDay THUNK => if (data) : ", data)
      }
      else {
        if(dev) console.log("gotMealCountByDay THUNK => else (data) : ", data)
      }
    }
    catch (error) {
      // dispatch(gotMealCountByDay({}))
      console.error('Whoops, trouble getting meal count by day!', error)
      dispatch(gotMealCountByDay({error: error}))
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}
export const getMealCountByType = (dateTime) => {
  return async (dispatch) => {
    if(dev) console.log("getMealCountByType THUNK => dateTime: ", dateTime)

    try {
      dispatch(setLoadingStatus(true))
      const { data } = await axios.get(`/api/meals/meal-count-last-30-days/${dateTime}`)

      if (data) {
        if(dev) console.log("gotMealCountByType THUNK => if (data) : ", data)
        dispatch(gotMealCountByType(data))
      }
      else {
        if(dev) console.log("gotMealCountByType THUNK => else (data) : ", data)
      }
    }
    catch (error) {
      // dispatch(gotMealCountByType({}))
      console.error('Whoops, trouble getting meal count by type!', error)
      console.log("error ==> ", error)
      // dispatch(gotMealCountByType(error))
      // dispatch(gotMealCountByType({error: error}))
      console.log("error.name ==> ", error.name)
      console.log("error.message ==> ", error.message)
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}



export const extractFoodText = (foodText, window) => {
  return async dispatch => {
    if(dev) console.log("extractFoodText THUNK => foodText: ", foodText)
    dispatch(setLoadingStatus(true))
    let res

    try {
      res = await axios.post(`/api/services/nutritionai/extract-food-text`, {'text': foodText})
    }
    catch (authError) {
      // console.log("Signup THUNK... authError ==> ", authError)
      console.error("authError: ", authError)

      let errorDeets = {
        ...authError.response.data,
        status: authError.response.status, // 401, etc
        statusText: authError.response.statusText, // "Unauthorized"
      }

      if(errorDeets.status === 401) {
        console.log(`ERROR in the ExtractFoodText THUNK.......
          statusText ==> ${errorDeets.statusText}
          errorDeets --->`, errorDeets)

        if(errorDeets.statusMessage === 'Meal already exists') {
          console.log("Meal already exists!! Dispatching error...")

          dispatch(gotMeal({error: errorDeets}))
          return "Duplicate"
        }

        return errorDeets.statusText // "Unauthorized"
      }
    }

    // ? ******   GET TASKID    ******
    let taskId
    try {
      if(dev) console.log("extractFoodText THUNK... gotTaskId res ==> ", res)
      if(res && res.data && res.status === 200) {
        taskId = res.data.task_id
        console.log("dispatching taskId.... taskId ==> ", taskId, typeof taskId)
        dispatch(gotTaskId(taskId))
      }
    }
    catch (taskIdErr) {
      console.error("taskIdErr: ", taskIdErr)
    }
    // ? ******   TRACK EVENTS    ******
    try {
      window.mixpanel.track("Text Meal Tracked", {
        'task_id': taskId,
        'food_text': foodText
      })
      window.mixpanel.track("Meal Tracked", {
        'meal_type': 'text',
        'task_id': taskId,
        'food_text': foodText
      })
      window.ReactPixel.trackCustom("logged_meal", {'meal_type': 'text'})
    }
    catch (trackingErr) {
      console.error("trackingErr: ", trackingErr)
    }
    // ? ******   CHECK TASK STATUS    ******
    try {
      if(dev) console.log("extractFoodText THUNK... checkTaskStatus() taskId ==> ", taskId)

      // TODO: get user's date (with timezone)
      let theMeal = await checkTaskStatus(taskId)
      console.log("theMeal => ", theMeal)

      if(theMeal) {
        dispatch(gotMeal(theMeal))
      }
    }
    catch (checkTaskStatusErr) {
      console.error("checkTaskStatusErr: ", checkTaskStatusErr)

      let errorDeets = {
        ...checkTaskStatusErr.response.data,
        status: checkTaskStatusErr.response.status, // 401, etc
        statusText: checkTaskStatusErr.response.statusText, // "Unauthorized"
        enteredText: checkTaskStatusErr.response.data.request_data, // "I ate... blah blah"
        // ! TODO: Add statusCode (to match image meal logic) once this data is avail from AI backend...
        // statusCode: checkTaskStatusErr.response.data.error_message, // "'list' object has no attribute 'keys' | Custom exception: No meal components | 'list' object has no attribute 'keys'"
      }
      console.log(`ERROR in the ExtractFoodText THUNK.......
        status ==> ${errorDeets.status}
        statusText ==> ${errorDeets.statusText}
        errorDeets --->`, errorDeets)

      dispatch(gotMeal({error: errorDeets}))
    }
  }
}
export const extractFoodImage = (foodImage, window) => {
  return async dispatch => {
    if(dev) console.log("extractFoodImage THUNK => foodImage: ", foodImage)
    dispatch(setLoadingStatus(true))
    let res

    try {
      res = await axios.post(`/api/services/nutritionai/extract-food-image`, {'image_url': foodImage})
    }
    catch (authError) {
      // console.log("Signup THUNK... authError ==> ", authError)
      console.error("authError: ", authError)

      let errorDeets = {
        ...authError.response.data,
        status: authError.response.status, // 401, etc
        statusText: authError.response.statusText, // "Unauthorized"
      }

      if(errorDeets.status === 401) {
        console.log(`ERROR in the extractFoodImage THUNK.......
          statusText ==> ${errorDeets.statusText}
          errorDeets --->`, errorDeets)

        if(errorDeets.statusMessage === 'Meal already exists') {
          console.log("Meal already exists!! Dispatching error...")

          dispatch(gotMeal({error: errorDeets}))
          return "Duplicate"
        }

        return errorDeets.statusText // "Unauthorized"
      }
    }

    // ? ******   GET TASKID    ******
    let taskId
    try {
      if(dev) console.log("extractFoodImage THUNK... gotTaskId res ==> ", res)
      if(res && res.data && res.status === 200) {
        taskId = res.data.task_id
        console.log("dispatching taskId.... taskId ==> ", taskId, typeof taskId)
        dispatch(gotTaskId(taskId))
      }
    }
    catch (taskIdErr) {
      console.error("taskIdErr: ", taskIdErr)
    }
    // ? ******   TRACK EVENTS    ******
    try {
      window.mixpanel.track("Image Meal Tracked", {
        'task_id': taskId
      })
      window.mixpanel.track("Meal Tracked", {
        'meal_type': 'image',
        'task_id': taskId,
      })
      window.ReactPixel.trackCustom("logged_meal", {'meal_type': 'image'})
    }
    catch (trackingErr) {
      console.error("trackingErr: ", trackingErr)
    }
    // ? ******   CHECK TASK STATUS    ******
    try {
      if(dev) console.log("extractFoodImage THUNK... checkTaskStatus() taskId ==> ", taskId)

      // TODO: get user's date (with timezone)
      let theMeal = await checkTaskStatus(taskId)
      console.log("theMeal => ", theMeal)

      if(theMeal) {
        dispatch(gotMeal(theMeal))
        if(theMeal.entryType === 'image') dispatch(uploadedMedia({}))
      }
    }
    catch (checkTaskStatusErr) {
      console.error("checkTaskStatusErr: ", checkTaskStatusErr)

      let errorDeets = {
        ...checkTaskStatusErr.response.data,
        status: checkTaskStatusErr.response.status, // 401, etc
        statusText: checkTaskStatusErr.response.statusText, // "Unauthorized"
        enteredImage: checkTaskStatusErr.response.data.request_data.image_url, // 'https://aimme-cdn.s3.amazonaws.com/d1af027e-9cc5-477c-aa46-0cd439be93be.jpg',
        statusCode: checkTaskStatusErr.response.data.error_message, // "'list' object has no attribute 'keys' | Custom exception: No meal components | 'list' object has no attribute 'keys'"
      }
      console.log(`ERROR in the ExtractFoodImage THUNK.......
        status ==> ${errorDeets.status}
        statusText ==> ${errorDeets.statusText}
        errorDeets --->`, errorDeets)

      dispatch(gotMeal({error: errorDeets}))
      if(errorDeets.status === 418 || errorDeets.status === 400) dispatch(uploadedMedia({}))
    }
  }
}

// export const searchFoodItems = (query) => async (dispatch) => {
export const searchFoodItems = (query) => {
  if(dev) console.log("searchFoodItems THUNK => query: ", query)

  return async dispatch => {
    try {
      dispatch(setLoadingStatus(true))

      const response = await axios.get(`/api/services/nutritionai/autocomplete-food?q=${query}`)
      // const response = await axios.get(`/api/services/nutritionai/autocomplete-food`, {
      //   params: { q: query },
      // })

      if(response.data) {
        dispatch(searchedFoodItems(response.data))
      }
      else {
        console.log("How to handle missing search list?")
        console.log("Dispatching as error... can't find any foods to match the search...")
        let errorDeets = {
          status: 204,
          statusText: 'No Content',
          statusMessage: 'No match found...',
          // statusCode: '', // Something techy & backend with ## values 🤷🏻‍♀️
        }
        dispatch(searchedFoodItems({error: errorDeets}))
      }
    }
    catch (error) {
      console.error('Error searching food items:', error)

      let errorDeets = {
        ...error.response.data,
        status: error.response.status, // 401, etc
        statusText: error.response.statusText, // "Unauthorized"
        // enteredText: checkTaskStatusErr.response.data.request_data // "food query"
        // statusCode: error.response.data.error_message, // "'list' object has no attribute 'keys' | Custom exception: No meal components | 'list' object has no attribute 'keys'"
      }
      console.log(`ERROR in the searchFoodItems THUNK.......
        status ==> ${errorDeets.status}
        statusText ==> ${errorDeets.statusText}
        errorDeets --->`, errorDeets)

      dispatch(searchedFoodItems({error: errorDeets}))
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}

// export const selectFoodItem = (foodId) => async (dispatch) => {
export const selectFoodItem = (foodId) => {
  return async (dispatch) => {
    if(dev) console.log("selectFoodItem THUNK => foodId: ", foodId)
  
    try {
      dispatch(setLoadingStatus(true))

      const response = await axios.get(`/api/services/nutritionai/get-food-item/${foodId}`)

      if(response.data) {
        dispatch(selectedFoodItem(response.data))
      }
      else {
        console.log("How to handle missing food item?")
        console.log(`Dispatching as error... NO DATA for selected food (ID #${foodId})!`)
        let errorDeets = {
          status: 204,
          statusText: 'No Content',
          statusMessage: 'Food details missing...',
          // statusCode: '', // Something techy & backend with ## values 🤷🏻‍♀️
        }
        dispatch(selectedFoodItem({error: errorDeets}))
      }
    }
    catch (error) {
      console.error('Error GETting selected food item:', error)
      let errorDeets = {
        ...error.response.data,
        status: error.response.status, // 401, etc
        statusText: error.response.statusText, // "Unauthorized"
        // foodId: checkTaskStatusErr.response.data.request_data // Number value?
        // statusCode: error.response.data.error_message, // "'list' object has no attribute 'keys' | Custom exception: No meal components | 'list' object has no attribute 'keys'"
      }
      console.log(`ERROR in the selectFoodItem THUNK.......
        status ==> ${errorDeets.status}
        statusText ==> ${errorDeets.statusText}
        errorDeets --->`, errorDeets)

      dispatch(selectedFoodItem({error: errorDeets}))
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}


// *** INITIAL STATE
const initialState = {
  loading: true,
  error: null,
  selected: {
    taskId: null,
    foodComponents: [],
    foods: [],
  },
  searchResults: [],
  // ! TODO: Update for ZOMBIE foods..
  foodSearch: {
    results: [],
    selected: null
  },
  all: [],
  forCalendar: {
    selected: [],
    // today: [],
    weekCount: [],
    monthCount: {},
  },
  count: {
    forSubscribe: []
  },
}

// *** REDUCER
const mealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOT_MEAL:
      console.log("GOT_MEAL => ", action.meal)
      if(action.meal && action.meal.id) {
        // return {...state, ...action.meal, loading: false, error: null}
        return {
          ...state, 
          selected: {...state.selected, ...action.meal}, 
          all: getUpdatedAll(state.all, action.meal),
          loading: false, 
          error: null
        }
      }
      else if(action.meal.error && action.meal.error.enteredText) {
        return {...state, error: action.meal.error, loading: false}
      }
      else if(action.meal.error && action.meal.error.enteredImage) {
        return {...state,
          error: action.meal.error,
          selected: {...initialState.selected},
          loading: false}
      }
      else return {...initialState, loading: false}
    case GOT_MEALS:
      console.log("GOT_MEALS => ", action.meals)
      // if(action.meals && action.meals.length) {
      if(action.meals && !action.meals.error) {
        return {
          ...state,
          all: action.meals,
          forCalendar: {
            ...state.forCalendar,
            selected: action.meals
          },
          loading: false, 
          error: null
        }
      }
      // else if(action.meals.error && action.meals.error.name) {
      else if(action.meals.error) {
        return {...state, error: action.meals.error, loading: false}
      }
      else return {...state, all: initialState.all, loading: false}
    case GOT_MEAL_COUNT_BY_DAY:
      console.log("GOT_MEAL_COUNT_BY_DAY => ", action.mealCount)
      // if(action.mealCount && action.mealCount.id) {
      let { mealCount } = action.mealCount
      console.log("mealCount ==> ", mealCount)
      let theInterval = mealCount.interval
      console.log("action.mealCount.interval => ", mealCount.interval)
      console.log("theInterval => ", theInterval)
      console.log("action.mealCount ==> ", action.mealCount)

      if(action.mealCount) {
        return {
          ...state,
          // count: {
          //   ...state.count,
          //   forCalendar: action.mealCount,
          // },
          forCalendar: {
            ...state.forCalendar,
            ...(theInterval === 'week' && { weekCount: action.mealCount.data }),
            // ...((theInterval === 'month') && { monthCount: action.mealCount.data }),
            monthCount: theInterval === 'month' ? mealCount.data : state.forCalendar.monthCount
          },

          loading: false, 
          error: null
        }
      }
      else if(action.mealCount.error) {
        return {...state, error: action.mealCount.error, loading: false}
      }
      else return {...initialState, loading: false}
    case GOT_MEAL_COUNT_BY_TYPE:
      console.log("GOT_MEAL_COUNT_BY_TYPE => ", action.mealCount)
      // if(action.mealCount && action.mealCount.id) {
      if(action.mealCount) {
        return {
          ...state,
          count: {
            ...state.count,
            forSubscribe: action.mealCount,
          },
          loading: false, 
          error: null
        }
      }
      else if(action.mealCount.error) {
        return {...state, error: action.mealCount.error, loading: false}
      }
      else return {...initialState, loading: false}
    case UPDATED_MEAL:
      console.log("UPDATED_MEAL => ", action.meal)
      if(action.meal && action.meal.id) {
        return {
          ...state, 
          selected: initialState.selected, 
          all: getUpdatedAll(state.all, action.meal),
          forCalendar: {
            ...state.forCalendar,
            selected: getUpdatedAll(state.forCalendar.selected, action.meal)
          },
          loading: false, 
          error: null
        }
      }
      else if(action.meal.error && action.meal.error.enteredText) {
        return {...state, error: action.meal.error, loading: false}
      }
      else return {...initialState, loading: false}
    case GOT_TASK_ID:
      console.log("GOT_TASK_ID => ", action.taskId)
      if(action.taskId && !action.taskId.error) {
        return {
          ...state, 
          selected: {
            ...state.selected,
            taskId: action.taskId
          },
          error: null
        }
      }
      else if(action.taskId.error && action.taskId.error.status) {
        return {...state, error: action.taskId.error, loading: false}
      }
      else return {...initialState, loading: false}
    case SET_LOADING_STATUS:
      console.log("SET_LOADING_STATUS => ", action.loading)
      return {...state, loading: action.loading}
    case LOGGED_OUT:
      console.log("LOGGED_OUT (meals) => ", action.reducers)
      if(action.reducers.includes('meals')) {
        console.log("meals reducer is included (in action)... return initialState!")
        return {...initialState, loading: false}
      }
      return {...initialState, loading: false}
    case SEARCHED_FOOD_ITEMS:
      console.log("SEARCHED_FOOD_ITEMS => ", action.foodItems)
      // Store search results for food items... temporarily.
      if(action.foodItems && !action.foodItems.error) {
        return {
          ...state,
          // ! TODO: Update for ZOMBIE foods..
          searchResults: action.foodItems,
          // ! TODO: Update for ZOMBIE foods...
          foodSearch: {
            results: action.foodItems,
            selected: null
          },
          error: null
        }
      }
      else if(action.foodItems.error && action.foodItems.error.status) {
        return {...state, error: action.foodItems.error, loading: false}
      }
      else return {...initialState, loading: false}
    case SELECTED_FOOD_ITEM:
      console.log("SELECTED_FOOD_ITEM => ", action.foodItem)
      // ! TODO: Update for ZOMBIE foods..
      // ~ Add the selected food item into the `foods` array of the `selected` meal object
      if(action.foodItem && !action.foodItem.error) {
        return {
          ...state,
          selected: {
            ...state.selected,
            // ! TODO: Update for ZOMBIE foods..
            foods: [...state.selected.foods, action.foodItem],
          },
          // ! TODO: Update for ZOMBIE foods...
          foodSearch: {
            results: [],
            selected: action.foodItem
          },
        }
      }
      else if(action.foodItem.error && action.foodItem.error.status) {
        return {...state, error: action.foodItem.error, loading: false}
      }
      else return {...initialState, loading: false}
    default:
      return state
  }
}

export default mealsReducer
