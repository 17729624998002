import { Fragment, useState, useEffect } from 'react'
import {connect} from 'react-redux'

import { useNavigate, Link } from 'react-router-dom'

import axios from 'axios'

// import { logout } from '../redux/theUser'
import { getMeal, updateMeal, getMeals, getMealCountByDay } from '../redux/meals'

import { DateTime } from 'luxon'

import { parseFoodsData } from '../utils/nutrition'
import { getDateTimeISO, getMonthStartISO } from '../utils/dateTimeManager'

// ******     MUI      ******
import { 
  Grid, Box, 
  Paper,
  // Card, CardHeader, CardContent,
  // TextField, Select, MenuItem, 
  // IconButton, Button, Chip,
  Typography,
  // Divider,
  // Fade,
} from '@mui/material'
// import {makeStyles} from '@material-ui/core/styles'
// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center'
//   }
// }))

// ******     COMPONENTS      ******
import { MealCalendar, DailyNutritionTotals, Tour } from './index'

// ******     UTILS      ******
import { extractMealDates } from '../utils/calendar'

// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'



const UserPage = (props) => {
  if(debug) console.log("UserPage PROPS: ", props)
  const {
    // & Redux state PROPS
    theUser, mealCount, meals,
    monthCount,
    getMeals, getMealCountByDay,
    // & passed in as PROPS
    theme
  } = props

  // const classes = useStyles()

  // *** STATES
  const [selectedDate, setSelectedDate] = useState(DateTime.now())
  // const [selectedMonth, setSelectedMonth] = useState(null)
  // const [selectedMonth, setSelectedMonth] = useState(DateTime.now().startOf('month').toISO())
  // const [selectedMonth, setSelectedMonth] = useState(DateTime.now().startOf('month').toFormat('yyyy-MM-dd'))
  const [selectedMonth, setSelectedMonth] = useState(getMonthStartISO(DateTime.now(), true).ISODate)
  // const [workoutCalendar, setWorkoutCalendar] = useState([])
  const [mealCalendar, setMealCalendar] = useState(mealCount)
  // const [mealCalendar, setMealCalendar] = useState(extractMealDates(meals))
  // const [mealsThisWeek, setMealsThisWeek] = useState(0)
  // const [userGoal, setUserGoal] = useState(0)
  // const [userGoalDialogOpen, setUserGoalDialogOpen] = useState(false)
  const [mealsToday, setMealsToday] = useState([])

  const navigate = useNavigate()

  // *** Handle user selection of a calendar date to view workout summary
  const onSelectDate =(ISODate) => {
    if(debug) console.log("onSelectDate().... ISODate ==> ", ISODate)
    // getMeals(ISODate, 'day')
    setSelectedDate(ISODate)
    navigate(`/summary/${ISODate}`)
  }
  const onMonthChange =(ISODate) => {
    if(debug) console.log("onMonthChange().... ISODate ==> ", typeof ISODate, ISODate)
    // getMeals(ISODate, 'month')
    // setSelectedMonth(DateTime.fromISO(ISODate))
    setSelectedMonth(ISODate)
    let convertedISODate = DateTime.fromISO(ISODate).toISO({ includeOffset: true })
    if(debug) console.log("convertedISODate ==> ", convertedISODate)
    getMealCountByDay(convertedISODate, 'month')
    // if(meals) setMealCalendar(extractMealDates(meals))
    // console.log("mealCalendar ==> ", mealCalendar)
  }

  useEffect(() => {
    if(debug) console.log("useEffect().... setMealCalendar() with monthCount ==> ", monthCount)
    if(monthCount) setMealCalendar(monthCount)
    if(debug) console.log("mealCalendar ==> ", mealCalendar)
  }, [monthCount])

  useEffect(async () => {
    if(debug) console.log("useEffect().... setMealsToday() with meals ==> ", meals)

    //#region     ******    TESTING: dateTime utils    ******
    // let userDateTimeNowToISOwithOffset = DateTime.now().toISO({ includeOffset: true })
    let rightNowISOs = getDateTimeISO(DateTime.now(), true)
    if(debug) console.log("useEffect().... setMealsToday() with rightNowISOs.ISOWithTimezone ==> ", rightNowISOs.ISOWithTimezone)

    /* // ^ ******    getMonthStartISO() OPTIONS    ******
      ? 3 PARAMETERS =====>  
      ^ (dateTime = DateTime.now(), returnAllFormats = false, includeTimezone = false)
    */
    let theMonthStartISOs = getMonthStartISO(DateTime.now(), true)
    // let theMonthStartISOs = getMonthStartISO(selectedDate, true)
    if(debug) console.log("useEffect().... setMealsMonth() with theMonthStartISOs ==> ", theMonthStartISOs)
    let plainISODate = theMonthStartISOs.ISODate
    if(debug) console.log("start of the month... plainISODate ==> ", plainISODate)
    //#endregion

    const { data } = await axios.get(`/api/meals/day/${DateTime.now().toISO({ includeOffset: true })}`)
    if(data) {
      let parsedData = data.map((item) => {
        return {...parseFoodsData(item)}
      })
      if(debug) console.log("setting mealsToday ===> ", parsedData)
      setMealsToday(parsedData)
    }
  }, [meals])


  return (
    <Grid container 
      justify="center"
      spacing={3}
      alignContent="center"
      justifyContent="center"
      sx={{ 
        // mt: -1, // ^ -8px
        mt: -2,
      }}
      >
      <Tour></Tour>

      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper 
          spacing={2}
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            boxShadow: 'none',
          }}
          component={Link} to={`/summary/${getDateTimeISO(DateTime.now(), true).ISODate}`}
          // component={Link} to={`/summary/${DateTime.now().toFormat('yyyy-MM-dd')}`}
          >
          <DailyNutritionTotals meals={mealsToday} />
        </Paper>
      </Grid>

      {/* <Grid item xs={12} sm={10} md={8} lg={10}>
        <Paper sx={{flexGrow: 1, textAlign: 'center'}}>
          {mealsThisWeek ? (
            <Typography variant="body1" gutterBottom>
              You've logged {mealsThisWeek} meals this week, that's
              awesome!
            </Typography>
          ) : (
            <Typography variant="body1" gutterBottom>
              Uh oh, you haven't logged any meals this week, better get
              snapping! 📸
            </Typography>
          )}
        </Paper>
      </Grid> */}

      <Grid item 
      xs={12} sm={10} md={8} lg={6} xl={4} 
      container
      justifyContent="center"
      >
        {/* <Paper spacing={2} sx={{flexGrow: 1, textAlign: 'center'}}> */}
        <Paper spacing={2} sx={{ textAlign: 'center', maxWidth: 'fit-content'}}>
          <MealCalendar
            selectedDate={selectedDate}
            selectedMonth={selectedMonth}
            mealDates={mealCalendar}
            onSelectDate={onSelectDate}
            onMonthChange={onMonthChange} 
            />
        </Paper>
      </Grid>

      {/* <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
        <Paper className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Your Progress
          </Typography>

          {userGoal ? (
            <ProgressBar
              data={progressBarData}
              total={userGoal}
              value={workoutsThisWeek}
            />
          ) : (
            <div>
              <Typography variant="body1" gutterBottom>
                Want to see how you're doing each week?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Set a goal to track your progress!
              </Typography>

              <UpdateGoalDialog
                userId={props.userId}
                userGoal={userGoal}
                onClose={handleCloseGoal}
                onClickOpen={handleClickGoal}
                open={userGoalDialogOpen}
              />
            </div>
          )}
        </Paper>
      </Grid> */}
    </Grid>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    theUser: state.theUser,
    // mealCount: state.meals.count.forCalendar,
    meals: state.meals.all,
    monthCount: state.meals.forCalendar.monthCount,
  }
}

const mapDispatch = (dispatch) => {
  return {
    // logout: () => dispatch(logout()),
    getMeals: (userDateTime, interval) => dispatch(getMeals(userDateTime, interval)),
    getMealCountByDay: (userDateTime, interval) => dispatch(getMealCountByDay(userDateTime, interval)),
  }
}

export default connect(mapState, mapDispatch)(UserPage)
