import axios from 'axios'

const dev = process.env.NODE_ENV === 'development'

// *** ACTION TYPES
const GOT_USER = 'GOT_USER'
const GOT_USERSUB = 'GOT_USERSUB'
const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
const UPLOADED_MEDIA = 'UPLOADED_MEDIA'
export const LOGGED_OUT = 'LOGGED_OUT'

// *** ACTION CREATORS
export const gotUser = (user) => ({
  type: GOT_USER,
  user
})
export const setLoadingStatus = (loading)  => ({
  type: SET_LOADING_STATUS,
  loading
})
export const uploadedMedia = (mediaInfo) => ({
  type: UPLOADED_MEDIA,
  mediaInfo
})
export const gotUserSub = (userSub) => ({
  type: GOT_USERSUB,
  userSub
})
export const loggedOut = (reducers) => ({
  type: LOGGED_OUT,
  reducers
})

// *** THUNK CREATORS
export const getUser = () => {
  return async (dispatch) => {
    if(dev) console.log("getUser THUNK => ")

    try {
      const {data} = await axios.get('/auth/me')
      if (data) {
        if(dev) console.log("getUser THUNK => if (data) : ", data)
        dispatch(gotUser(data))
      }
      else {
        if(dev) console.log("getUser THUNK => else (data) : ", data)
        dispatch(gotUser({id: null}))
        dispatch(loggedOut(['theUser', 'meals', 'subscriptions']))
      }
    }
    catch (error) {
      // dispatch(gotUser({}))
      console.error('Whoops, trouble getting user!', error)
      dispatch(gotUser({id: null}))
      dispatch(loggedOut(['theUser', 'meals', 'subscriptions']))
    }
    finally {
      dispatch(setLoadingStatus(false))
    }
  }
}
export const login = (userCreds) => {
  return async dispatch => {
    try {
      const {data} = await axios.put('/auth/login', userCreds)

      try {
        console.log('identifying mixpanel ==> ', userCreds.email)
        window.mixpanel.identify(userCreds.email)
        window.mixpanel.track("User identified")
        window.mixpanel.track("PageView")        
      }
      catch (error) {
        console.log("mixpanel failed ==> ", error)
      }

      dispatch(gotUser(data))
    }
    catch (error) {
      console.error(error)
    }
  }
}
export const logout = () => {
  return async dispatch => {
    try {
      await axios.delete('/auth/logout')
      dispatch(gotUser({id: null}))
      dispatch(loggedOut(['theUser', 'meals', 'subscriptions']))
    }
    catch (error) {
      console.error(error)
    }
  }
}
export const forgotPass = (userInfo) => {
  return async dispatch => {
    if(dev) console.log("forgotPass THUNK => userInfo: ", userInfo)
    try {
      const {data} = await axios.patch(`/auth/forgotpass`, userInfo)
      console.log("Success! => ", data)
      return data
    }
    catch (error) {
      // TODO: manage error object in reducer...
      console.error("forgotPassError: ", error)
      return "Email not found!"
    }
  }
}
export const resetPass = (userInfo) => {
  return async dispatch => {
    if(dev) console.log("resetPass THUNK => userInfo: ", userInfo)
    try {
      const {data} = await axios.patch(`/auth/resetpass`, userInfo)
      console.log("Success! => ", data)
      return data
    }
    catch (error) {
      // TODO: manage error object in reducer...
      console.error("resetPassError: ", error)
      return "Token expired!"
    }
  }
}
export const signup = (userInfo) => {
  return async dispatch => {
    if(dev) console.log("Signup THUNK => userInfo: ", userInfo)
    let res
    try {
      res = await axios.post(`/auth/signup`, userInfo)

      try{
        console.log('identifying mixpanel ==> ', userInfo.email)
        window.mixpanel.identify(userInfo.email)
        window.ReactPixel.track("CompleteRegistration")
        window.mixpanel.track("CompleteRegistration")
      }
      catch (error) {
        console.log("signup tracking error ==> ", error)
      }

    }
    catch (authError) {
      // console.log("Signup THUNK... authError ==> ", authError)
      console.error("authError: ", authError)

      let errorDeets = {
        ...authError.response.data,
        status: authError.response.status, // 401, etc
        statusText: authError.response.statusText, // "Unauthorized"
      }

      if(errorDeets.status === 401) {
        console.log(`ERROR in the Signup THUNK.......
          statusText ==> ${errorDeets.statusText}
          errorDeets --->`, errorDeets)

        if(errorDeets.statusMessage === 'User already exists') {
          console.log("User already exists!! Dispatching error...")

          dispatch(gotUser({error: errorDeets}))
          return "Duplicate"
        }

        return errorDeets.statusText // "Unauthorized"
      }
    }

    try {
      // console.log("Signup THUNK... res ==> ", res)
      if(res && res.data && res.status === 200) {
        dispatch(gotUser(res.data))
        return res.statusText // "OK"
      }
    }
    catch (dispatchOrHistoryErr) {
      console.error("dispatchOrHistoryErr: ", dispatchOrHistoryErr)
    }
  }
}

export const uploadMedia = (theFile) => {
  return async (dispatch) => {
    if(dev) console.log(`uploadMedia THUNK => `)

    try {
      dispatch(uploadedMedia({loading: true}))

      // Create a FormData instance and append the file
      const formData = new FormData();
      formData.append('file', theFile); // The key 'file' should match the key expected in multer in your backend

      // Make an axios POST request and send the FormData
      const { data } = await axios.post(`/api/services/aws/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important: Set the content type to 'multipart/form-data'
        },
      });
      
      if (data) {
        if(dev) console.log("The TRY bit of data: ", data)
        let fileInfo = data.data
        dispatch(uploadedMedia({...fileInfo, loading: false}))
      }
      else {
        console.log("Uh oh, no data from uploadMedia() POST to AWS!")
        if(dev) console.log("Generating ERROR for unavail / missing data on uploadedMedia...")
        let errorDeets = {
          // ...error.response.data,
          status: 204, // 401, etc
          statusText: 'No Content', // "Unauthorized"
          statusMessage: 'Upload details unavailable...'
          // enteredText: error.response.data.request_data // "food query"
          // statusCode: error.response.data.error_message, // "'list' object has no attribute 'keys' | Custom exception: No meal components | 'list' object has no attribute 'keys'"
        }
        console.log(`ERROR in the uploadMedia THUNK.......
          status ==> ${errorDeets.status}
          statusText ==> ${errorDeets.statusText}
          errorDeets --->`, errorDeets)
  
        dispatch(uploadedMedia({error: errorDeets}))
      }
    }
    catch (error) {
      console.error('Whoops, trouble uploading media!', error)
      let errorDeets = {
        ...error.response.data,
        status: error.response.status || 400, // 401, etc
        statusText: error.response.statusText || 'Bad Request', // "Unauthorized"
        statusMessage: 'Whoops, trouble uploading media! Please wait a moment & try again.', // 'Whoops, trouble uploading media! Please wait a moment & try again.'
        // enteredText: error.response.data.request_data // "food query"
        // statusCode: error.response.data.error_message, // "'list' object has no attribute 'keys' | Custom exception: No meal components | 'list' object has no attribute 'keys'"
      }
      console.log(`ERROR in the uploadedMedia THUNK.......
        status ==> ${errorDeets.status}
        statusText ==> ${errorDeets.statusText}
        errorDeets --->`, errorDeets)

      dispatch(uploadedMedia({error: errorDeets}))
    }
  }
}

export const getUserSub = () => {
  return async dispatch => {
    if(dev) console.log("getUserSub THUNK => ")

    try {
      const response = await axios.get(`/api/usersubs/latest`)
      // console.log("GET userSubs/latest... response => ", response)
      let { status, data } = response

      if(status !== 204 && data && data !== "") {
        console.log("User has a latest userSub!")
        dispatch(gotUserSub(data))
      }
      else {
        console.log("User has NO userSub!")
        dispatch(gotUserSub({}))
      }
    }
    catch (error) {
      console.error('Error fetching latest userSub:', error)
      // Handle error appropriately
    }
  }
}

// *** INITIAL STATE
const initialState = {
  loading: true,
  error: null,
  mediaInfo: {
    loading: false
  },
  userSubs: {
    current: null,
    all: [] // history
  },
}

// *** REDUCER
const theUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOT_USER:
      console.log("GOT_USER => ", action.user)
      if(action.user && action.user.id) {
        return {...state, ...action.user, loading: false, error: null}
      }
      else if(action.user.error && action.user.error.enteredEmail) {
        return {...state, email: action.user.error.enteredEmail, error: action.user.error, loading: false}
      }
      else return {...initialState, loading: false}
    case GOT_USERSUB:
      console.log("GOT_USERSUB => ", action.userSub)
      return {
        ...state,
        userSubs: {
          ...state.userSubs, 
          current: action.userSub
        },
      }
    case SET_LOADING_STATUS:
      console.log("SET_LOADING_STATUS => ", action.loading)
      return {...state, loading: action.loading}
    case UPLOADED_MEDIA:
      console.log("UPLOADED_MEDIA => ", action.mediaInfo)
      if(action.mediaInfo && !action.mediaInfo.error) {
        return {...state, mediaInfo: {...action.mediaInfo}, loading: false}
      }
      else if(action.mediaInfo.error && action.mediaInfo.error.status) {
        return {
          ...state,
          error: action.mediaInfo.error,
          mediaInfo: {...state.mediaInfo, loading: false},
          loading: false
        }
      }
      else return {...state, mediaInfo: {...initialState.mediaInfo}, loading: false}
    case LOGGED_OUT:
      console.log("LOGGED_OUT (theUser) => ", action.reducers)
      if(action.reducers.includes('theUser')) {
        if(dev) console.log("theUser reducer is included (in action)... return initialState!")
        return {...initialState, loading: false}
      }
      return {...initialState, loading: false}
    default:
      return state
  }
}

export default theUserReducer
