import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'

let debug = localStorage.getItem("debuggerMode") === 'true'


export default function StandardImageList(props) {
  if(debug) console.log("StandardImageList PROPS: ", props)
  const {
    // & Redux PROPS

    // & passed in as PROPS
    imgData
  } = props


  return (
    <ImageList
      sx={{ width: 57, height: 57 }}
      // cols={3}
      cols={1}
      rowHeight={57}
      >
      {imgData.map((image, index) => (
        <ImageListItem key={index} >
            <img
              // srcSet={`${item.img}?w=60&h=60&fit=crop&auto=format&dpr=2 2x`}
              // src={`${item.img}?w=60&h=60&fit=crop&auto=format`}
              src={`${image.URL}?w=60&h=60&fit=crop&auto=format`}
              // alt={item.title}
              alt={image.title}
              loading="lazy"
              style={{
                borderRadius: '0.75rem',
                borderWidth: '1px',
                borderColor: 'rgba(86,88,105,1)'
              }}
            />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

