import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'

import { DateTime } from 'luxon'

import SubscribeRoutes from './SubscribeRoutes'
import { withSubscribeBanner } from '../../utils/withSubscribeBanner'


import { 
  // LandingPage, Home, 
  LandingPage,
  Login, UserPage, SignUp, 
  ForgotPassword, ResetPassword,
  Chatbot, NutritionTable,
  MealFoodGrid, MealsGrid,
  // Subscribe, Product, SubscriptionManager
 } from '../index'

import { getUser } from '../../redux/theUser'
import { getMealCountByDay, getMealCountByType, getMeals } from '../../redux/meals'

let debug = localStorage.getItem("debuggerMode") === 'true'

const AllRoutes = (props) => {
  if(debug) console.log("AllRoutes PROPS: ", props)

  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => !!state.theUser.id)
  const allDemMeals = useSelector(state => state.meals.all)

  useEffect(() => {
    dispatch(getUser())
  }, [])

  useEffect(() => {
    if(debug) console.log("AllRoutes useEffect()... isLoggedIn ==> ", isLoggedIn)
    const userDateTime = DateTime.now().toISO({ includeOffset: true })

    /* // ***  GET UPDATED MEAL COUNTS if allDemMeals (array) changes
      ^ Updated meal counts trigger subscription gating.
    */
    if(isLoggedIn) {
      if(debug) console.log("dispatching getMealCounts byDay & byType... based on userDateTime ==> ", userDateTime)
      dispatch(getMealCountByDay(userDateTime, 'month'))
      // ******  MEAL COUNTS for SUBSCRIPTION GATING  ******
      dispatch(getMealCountByType(userDateTime))
      // dispatch(getMeals(userDateTime, 'month'))
    }
  }, [isLoggedIn, allDemMeals])


  const navigate = useNavigate()

  useEffect(() => {
    trackPageView() // To track the first pageview upon load
  }, [navigate])

  // TODO: Move to separate utils...
  function trackPageView() {
    if(debug) console.log('PageView tracked for location.pathname ==> ', location.pathname)
    window.mixpanel.track("PageView")
  }

  // *** Create the wrapped MealFoodGrid with a banner or a tour
  const MealFoodGridWithBanner = withSubscribeBanner(MealFoodGrid)

  return (
    <div>
      <main>
        {/* //******    NOT Logged In   ****** */}
        {!isLoggedIn && (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Navigate to="/login" replace />} />
            <Route path="/chat" element={<Navigate to="/login" replace />} />
            <Route path="/meal" element={<Navigate to="/login" replace />} />
            <Route path="/meal/*" element={<Navigate to="/login" replace />} />
            <Route path="/summary" element={<Navigate to="/login" replace />} />
            <Route path="/summary/*" element={<Navigate to="/login" replace />} />
            <Route path="/home" element={<Navigate to="/login" replace />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/" element={<LandingPage />} />

            {/* //& ******    PASSWORD RESET     ****** */}
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            {SubscribeRoutes()}
          </Routes>
        )}

        {/* //******    Logged In   ****** */}
        {isLoggedIn && (
          <Routes>
            <Route path='/login' element={<Navigate to="/chat" replace />} />
            <Route path='/signup' element={<Navigate to="/chat" replace />} />
            <Route path="/profile" element={<UserPage />} />
            <Route path="/chat" element={<Chatbot />} />

            {/* <Route path="/meal" element={<MealFoodGrid />}> */}
            <Route path="/meal">
            {/* <Route path="/meal" element={<Navigate to="/meal/new" replace />}> */}
              <Route path=":mealId" element={<MealFoodGrid />} />
              <Route path="new" element={<MealFoodGridWithBanner />} />
              <Route path="demo" element={<MealFoodGrid />} />
              <Route path="/meal/" element={<Navigate to="/meal/new" replace />} />
            </Route>
            

            <Route path="/summary" element={<MealsGrid />}>
              <Route path=":mealsDate" element={<MealsGrid />} />
            </Route>

            <Route path="/home" element={<UserPage />} />

            <Route path="/" element={<Navigate to="/chat" replace />} />

            {/* <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/subscription" element={<SubscriptionManager />} />
            <Route path="/products" element={<Product />} /> */}
            {SubscribeRoutes()}
          </Routes>
        )}
      </main>
    </div>
  )
}

export default AllRoutes
