import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

// ******     COMPONENTS      ******
import { NavBar } from './components'
//? ROUTES to React components GO HERE!
import AllRoutes from './components/routes'

// ******     THEME      ******
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'

// #region    ******    TRACKING      ******
import ReactPixel from 'react-facebook-pixel'
window.ReactPixel = ReactPixel
import mixpanel from 'mixpanel-browser'
import Hotjar from '@hotjar/browser'
import { clarity } from 'react-microsoft-clarity'

mixpanel.init("45db4c3f948be1946b44286abfa25557")
window.mixpanel = mixpanel

const query = new URLSearchParams(location.search)
const mixpanelDistinctId = query.get('mixpanel_distinct_id')
if(mixpanelDistinctId){
  console.log('mixpanel_distinct_id found ==> ', mixpanelDistinctId)
  mixpanel.identify(mixpanelDistinctId)
}

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
ReactPixel.init('386078470600924', options)
ReactPixel.pageView()

const siteId = 3815343
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

clarity.init('mq9uww1uto')
clarity.consent()
// #endregion


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <NavBar />
        {/* //^ Rest of the app (or routes for components) goes here! */}
        <AllRoutes />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
