// ^ [https://mui.com/customization/theming/#createtheme-options-args-theme]
// Theme composition: using theme options to define other options
// import zIndex from '@material-ui/core/styles/zIndex'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

// ^ default theme obj [https://mui.com/material-ui/customization/default-theme/]

// import './static/style.css'
// import style from './static/fonts/fonts.css'
import './static/fonts/fonts.css'
// import samim from "./assets/fonts/samim.ttf";
// import './static/fonts/Gilroy-SemiBold.tff'
// import GilroySemiBold from './static/fonts/Gilroy-SemiBold.tff'
// const gilroySemiBold = {
//   // fontFamily: 'Gilroy-SemiBold',
//   fontFamily: 'Gilroy',
//   fontStyle: 'semi-bold',
//   fontDisplay: 'swap',
//   fontWeight: '600',
//   src: `
//     local('Gilroy'),
//     local('Gilroy-SemiBold'),
//     url(${GilroySemiBold}) format('ttf')
//   `,
// //   unicodeRange:
// //     'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
//  }

let theme = createTheme({
  palette: {
    primary: {
      // light: '#8efaf5',
      // light: '#a5e8e5',
      // main: '#1ebfbf', // SurfsharkPrimaryTeal
      // dark: '#178a9e', // SurfsharkPrimaryTealDarkened
      light: '#b7e8e6',
      main: '#00b5ad', //teal
      dark: '#059b93',
      contrastText: "#ffffff",
    },
    // secondary: {
    //   light: '#d2d4f7',
    //   // main: '#6369D1', //purple-ish
    //   main: '#b306ab', //pinkish-purple
    //   dark: '',
    //   contrastText: '#ffffff',
    // },
    secondary: {
      light: '#f508ea', // +4 [https://0to255.com/b306ab]
      main: '#b306ab', // pinkish-purple || "Violet Eggplant" [https://colorbase.app/ja/colors/b306ab]
      dark: '#71046c', // -4 [https://0to255.com/b306ab]
      contrastText: '#ffffff',
    },
    // tertiary: {
    //   light: '#8a7edd', //dark lavender
    //   main: '#5e4cd5', //purple-ish
    //   dark: '#1c08a5', //deep purple
    //   contrastText: "#ffffff",
    // },
    tertiary: {
      light: '#e5705c', // +3  [https://0to255.com/de4930]
      main: '#de4930', // burnt orange || "Astorath Red"
      dark: '#bd351e', // -3  [https://0to255.com/de4930]
      contrastText: "#010101", // dark navy?
    },
    // ~ [https://huemint.com/illustration-1/#palette=010101-4d2f7d-00b5ad-ee9e2c-de4930-2e2b28]
    // ~ [https://huemint.com/illustration-1/#palette=e9eff5-4d2f7d-00b5ad-ee9e2c-de4930-c9d9e4]
    burntOrange: {
      light: '#e5705c', // +3  [https://0to255.com/de4930]
      main: '#de4930', // burnt orange || "Astorath Red"
      // main: '#ff4c64', // surfsharkSecondaryOrange
      dark: '#bd351e', // -3  [https://0to255.com/de4930]
      contrastText: "#010101", // dark navy?
    },
    eggplant: {
      light: '#643da2', // +3 [https://0to255.com/#4d2f7d]
      main: '#4d2f7d', // medium purple || "Amethyst Purple"
      dark: '#362158', // -3 [https://0to255.com/#4d2f7d]
      contrastText: "#ffffff",
    },
    mustard: {
      light: '#f2b45b', // +3 [https://0to255.com/ee9e2c]
      main: '#ee9e2c', // "Nuclear Mango" [https://icolorpalette.com/color/ee9e2c]
      dark: '#d68511', // -3 [https://0to255.com/ee9e2c]
      // contrastText: '#1f1f1f',
      contrastText: "#010101", // dark navy?
    },
    nutritionFat: {
      main: '#ffb74d',
    },
    nutritionCarbs: {
      // main: 'rgba(2, 178, 175, 1)'
      main: '#02b2af',
    },
    nutritionProtein: {
      // main: 'rgb(144, 1, 203)'
      main: '#9001cb',
    },
    // background: {
    //   // default: '#fff' //white
    //   // default: '#8a57ea0d'
    // },
    silvery: {
      main: '#c9d9e4', // ice grey
      // contrastText: '#1f1f1f', //SUPER dark grey
      contrastText: "#010101", // dark navy?
    },
    neutral: {
      light: grey[200],
      // light: '#808080', //moderate light grey
      main: '#42495b', //moderate dark grey
      dark: '#1f1f1f' //SUPER dark grey
    },
    white: {
      main: '#fff'
    },

    lightBackground: {
      // lightest: '#f5f5f5', //whitesmoke
      light: '#f9f9f9', // SurfsharkHella AlmostWhite
      main: '#eee', // SurfsharkHellaLightGrey
      dark: '#c4c4c5', // SurfsharkDarkestLightGrey
      // contrastText: '#423d3d', // p || body
      // contrastText: '#16191c', // header || titles
    },
    darkBackground: {
      // light: '#242e38', // Bluer-SurfsharkDarkGrey
      main: '#2e3033', // SurfsharkDarkGrey
      dark: '#16191c' // Surfshark AlmostBlack
      // contrastText: '#fff', // p || body
      // contrastText: '#a3a4a6', // header || titles
    },
    // background: {
    //   paper: '#ff',
    //   default: '#fff'
    // },
    // ~ [https://mui.com/material-ui/customization/palette/#provide-tokens-manually]
    // ochre: {
    //   main: '#E3D026',
    //   light: '#E9DB5D',
    //   dark: '#A29415',
    //   contrastText: '#242105',
    // },
  },
  typography: {
    fontFamily: 'Raleway, Montserrat, Lato, Nunito Sans, sans-serif',
    fontSize: 12,
    // letterSpacing: '-.4px',
    // h1: {
    //   fontFamily: 'Raleway, Montserrat, Lato, Nunito Sans, sans-serif',
    //   fontWeight: 600,
    // },
    // h2: {
    //   fontFamily: 'Baskervville, Garamond, Libre Caslon Text, serif',
    // },
    // h3: {
    //   fontFamily: 'Raleway, Montserrat, Lato, Nunito Sans, sans-serif',
    //   fontWeight: 600,
    // },
    // strong: {
    //   fontFamily: 'Raleway, Montserrat, Lato, Nunito Sans, sans-serif',
    //   fontWeight: 600,
    // },
    h4: {
      fontFamily: 'GilroyRegular, sans-serif',
      letterSpacing: '.04rem'
    },
    // h5: {
    //   fontFamily: 'GilroySemiBold, sans-serif'
    // }
    // *** NEW Font (Griswald match)
    // #region
    // // body {
    // //   font-weight: 400;
    // //   font-size: 1.6rem;
    // //   line-height: 1.5;
    // //   letter-spacing: -.4px;
    // //   -webkit-font-smoothing: antialiased;
    // //   -moz-osx-font-smoothing: grayscale;
    // // }
    // h1, h2: {
    //   letterSpacing: '-.05rem',
    // },
    // h1: {
    //   // .styles_title {
    //   //   font-size: 3.2rem;
    //   //   font-weight: 700;
    //   //   line-height: 1.19;
    //   //   letter-spacing: -.5px;
    //   //   margin-bottom: 16px;
    //   //   text-align: center;
    //   //   color: #16191c;
    //   // }
    //   letterSpacing: '-.05rem',
    // },
    // h2:{
    //   // .styles_title {
    //   //   font-size: 3.2rem;
    //   //   font-weight: 700;
    //   //   line-height: 1.19;
    //   //   letter-spacing: -.5px;
    //   //   margin-bottom: 0;
    //   //   color: #fff;
    //   //   word-wrap: break-word;
    //   // }
    // },
    // h3: {
    //   // .styles_boxTitle {
    //   //   font-size: 2.4rem;
    //   //   font-weight: 700;
    //   //   line-height: 1.42;
    //   //   letter-spacing: .25px;
    //   //   margin-bottom: 16px;
    //   //   color: #16191c;
    //   // }
    //   letterSpacing: '.025rem'
    // },
    // h4: {
    //   // .styles_title.styles_small (h3) {
    //   //   font-size: 1.8rem;
    //   //   font-weight: 700;
    //   //   line-height: 1.44;
    //   //   letter-spacing: -.2px;
    //   //   margin-bottom: 0;
    //   //   color: #a3a4a6;
    //   // }
    //   letterSpacing: '-.02rem'
    // },
    // h6: {
    //   // .styles_listTitle {
    //   //   font-size: 1.2rem;
    //   //   font-weight: 400;
    //   //   line-height: 1.33;
    //   //   letter-spacing: .5px;
    //   //   color: #a3a4a6;
    //   //   margin-bottom: 16px;
    //   //   -webkit-user-select: none;
    //   //   -moz-user-select: none;
    //   //   user-select: none;
    //   // }
    //   letterSpacing: '.05rem',
    // },
    // ul: {
    //   // .styles_list {
    //   //   list-style: none;
    //   //   transition: max-height 1s cubic-bezier(0,1,0,1);
    //   //   margin-bottom: 32px;
    //   // }
    // },
    // li: {
    //   // .styles_listItem:last-child {
    //   //   margin-bottom: unset;
    //   // }
    //   // .styles_listItem {
    //   //     font-size: 1.2rem;
    //   //     font-weight: 400;
    //   //     line-height: 1.33;
    //   //     letter-spacing: .5px;
    //   //     color: #fff;
    //   //     padding: 2px 0;
    //   //     margin-bottom: 8px;
    //   // }
    //   letterSpacing: '.05rem',
    // },
    // p: {
    //   // font-size: 1.8rem;
    //   // font-weight: 400;
    //   // line-height: 1.44;
    //   // letter-spacing: -.2px;
    //   // margin-bottom: 16px;
    //   // text-align: center;
    //   // color: #16191c;
    //   // letterSpacing: '-.2px',
    //   letterSpacing: '-.02rem',
    // },
    // a: {
    //   // .styles_link {
    //   //   display: flex;
    //   //   border: none;
    //   //   cursor: pointer;
    //   //   text-align: left;
    //   //   font-size: 1.6rem;
    //   //   font-weight: 700;
    //   //   line-height: 1.5;
    //   //   letter-spacing: -.1px;
    //   //   margin-bottom: 0;
    //   // }
    //   letterSpacing: '-.01rem'
    // },
    // // *** STYLES | Custom   ***
    // // TAGS / SUBHEADERS / ?
    // styles_extraSmall: {
    //   fontSize: '.9rem',
    //   fontWeight: 700,
    //   lineHeight: 1.11,
    //   // letterSpacing: '1px',
    //   letterSpacing: '.1rem',
    //   marginBottom: 0,
    //   textTransform: uppercase,
    //   borderRadius: '32px',
    // },
    // // .styles_tag {
    // //   width: max-content;
    // //   max-width: 100%;
    // //   display: flex;
    // //   gap: 8px;
    // //   align-items: center;
    // //   text-align: center;
    // //   border: 1px solid;
    // //   padding: 8px 16px;
    // // },
    // // .styles_small {
    // //   font-size: 1.4rem;
    // //   font-weight: 700;
    // //   line-height: 1.57;
    // //   letter-spacing: .1px;
    // //   margin-bottom: 0;
    // //   border-radius: 32px;
    // // }
    // // ? XS <p>
    // // .styles_copyright .styles_legal {
    // //   font-size: 1.2rem;
    // //   font-weight: 400;
    // //   line-height: 1.33;
    // //   letter-spacing: .5px;
    // //   display: flex;
    // //   justify-content: flex-start;
    // //   margin-bottom: 16px;
    // //   padding: 2px 0;
    // //   color: #fff;
    // // }
    // // ? ICON Title?
    // // .styles_iconTitle {
    // //   font-size: 1.2rem;
    // //   font-weight: 400;
    // //   line-height: 1.33;
    // //   letter-spacing: .5px;
    // //   margin-bottom: 0;
    // //   display: block;
    // // }
    // // ? BUTTONS
    // // .styles_button {
    // //   font-size: 1.6rem;
    // //   font-weight: 700;
    // //   line-height: 1;
    // //   letter-spacing: 0;
    // //   margin-bottom: 0;
    // //   display: flex;
    // //   align-items: center;
    // //   justify-content: center;
    // //   text-align: center;
    // //   border: 2px solid;
    // //   border-radius: 12px;
    // //   cursor: pointer;
    // //   transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
    // //   width: 100%;
    // // }
    // // .styles_button.styles_small {
    // //   font-size: 1.4rem;
    // //   font-weight: 700;
    // //   line-height: 1.14;
    // //   letter-spacing: 0;
    // //   margin-bottom: 0;
    // //   height: 40px;
    // //   padding: 12px;
    // // }
    // #endregion
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //       '@global': {
    //           '@font-face': [Inter, Nunito],
    //       },
    //   },
    // },
    // ~ [https://surajsharma.net/blog/react-material-ui-custom-font]
    // overrides: {
    //   MuiCssBaseline: {
    //     '@global': {
    //       '@font-face': [gilroySemiBold],
    //     },
    //   }
    // },
    // ~ [https://stackoverflow.com/a/72847009]
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'GilroySemiBold';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 600;
    //       src: local('Gilroy'), local('Gilroy-SemiBold'), url(${GilroySemiBold}) format('truetype');
    //     }
    //   `,
    // },
    MuiGrid: {
      // styleOverrides: {
      //   // root: {},
      //   item: {
      //     paddingTop: '0px',
      //     paddingBottom: '24px'
      //   }
      // }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '60px',
          '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            'webkitAppearance': 'none',
            margin: 0,
          },
          '& input[type=number]': {
            'mozAppearance': 'textfield', // Firefox
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekContainer: {
          paddingTop: '5px',
        }
      }
    }
    // MuiCardHeader: {
    //   variants: [
    //     {
    //       props: { variant: 'condensed' },
    //       style: {
    //         textTransform: 'none',
    //         border: `2px dashed ${blue[500]}`,
    //       },
    //     },
    //   ],
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       padding: '16px 5px 16px 5px',
    //     }
    //   }
    // }
  },


  // zIndex: {
  //   appBar: zIndex.drawer + 1
  // }
})

theme = responsiveFontSizes(theme)

export default theme
