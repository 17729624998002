import {Component} from 'react'
import {connect} from 'react-redux'

import {getUser, logout} from '../redux/theUser'

// import Login from './Login'
// import UserPage from './UserPage'


let debug = localStorage.getItem("debuggerMode") === 'true'
class unconnectedHome extends Component {
    componentDidMount() {
      // console.log("Home PROPS: ", this.props)
      // this.props.getUser()
    }

    render() {
        let theUser = this.props.theUser
        // TODO: move "loading" functionality?? Only remaining logic that hasn't been replicated elsewhere...
        if (theUser.loading) return <div>loading...</div>
        if (theUser.id) return <UserPage user={theUser} onClick={() => this.props.logout()} />

        return <Login />
    }
}

const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    theUser: state.theUser
  };
};

const mapDispatch = (dispatch) => {
  console.log('Mapping dispatch to props')
  return {
    getUser: () => dispatch(getUser()),
    logout: () => dispatch(logout())
  }
}

const Home =  connect(mapState, mapDispatch)(unconnectedHome)

export default Home
