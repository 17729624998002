import { Fragment, useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

import { useParams, useNavigate, useLocation } from 'react-router-dom'

import { DateTime } from 'luxon'

import { getMeal, gotMeal, updateMeal } from '../../redux/meals'

// ******     MUI      ******
import { 
  Grid,
  Card, CardHeader, CardContent,
  IconButton, Button,
  Typography,
  Divider,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import CancelIcon from '@mui/icons-material/Cancel'

// ******     COMPONENTS      ******
import { MealFoodSelector, MealFoodInput, MealFoodNutrition, ConfirmDialog, FoodSearch, Tour } from '../index'

// ******     UTILS      ******
import { handleFoodRemoval, getMealNutritionTotals } from '../../utils/nutrition'
import { isUserSubActive } from '../../utils/subscribe'
import { countLargestMealEntryTypes } from '../../utils/checkMealLogging'

// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'

const MealFoodGrid = (props) => {
  if(debug) console.log("MealFoodGrid PROPS: ", props)
  const {
    // & Redux PROPS
    isMealLoading,
    selectedMeal, foods,
    getMeal, updateMeal,
    // & passed in as PROPS
    disableSave
  } = props

// //#region ******    HOOKS & FUNCS    ******
  let { mealId } = useParams()  
  
  if(debug) console.log("useParams()... mealId => ", mealId)

  const location = useLocation()
  
  if(debug) console.log("location.pathname ==> ", location.pathname)
  if(!mealId) {
    if(debug) console.log("Missing mealId... checking for other path deets in url...")
    const trimmedURL = location.pathname.replace("/meal/", "")
    if(debug) console.log("trimmedURL ==> ", trimmedURL)
      
    if(trimmedURL === 'new' || trimmedURL === 'demo'){
      mealId = 'new'
    }
  }

  // ******   GET MEAL, if necessary, from URL  ******
  useEffect(() => {
    if(mealId) {
      if(mealId === 'new') {
        // ! TODO: Add navigation to somewhere else?
        if(debug) console.log(`Check for selectedMeal in Redux...   #${selectedMeal?.id}
        if there ain't one, gotta navigate() somewhere else... /chat? /home?`)
        setIsNewMeal(true)
      }
      else {
        mealId = parseInt(mealId)
        getMeal(mealId)
      }
      
    }
  }, [])

  // ******   STATES  ******
  const [isNewMeal, setIsNewMeal] = useState(mealId === 'new')
  const [isDummyTourMeal, setIsDummyTourMeal] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [mealType, setMealType] = useState(null)
  const [isV2ImageMeal, setIsV2ImageMeal] = useState(selectedMeal?.apiVersion && (selectedMeal?.entryType === 'image') ? selectedMeal.apiVersion === 'v2' : null)
  // const [foodItems, setFoodItems] = useState([])
  const [foodItems, setFoodItems] = useState(null)
  const [mealTotals, setMealTotals] = useState({
    calories: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
    fiber: 0,
    // *** for DOUBLE-CHECKING calcs ***
    kcals: 0,
  })
  
  // ******   Selectors  ******
  const latestUserSub = useSelector(state => state.theUser.userSubs.current)
  const mealsForSubscribe = useSelector(state => state.meals.count.forSubscribe)  

  let isDemoPage = location.pathname.replace("/meal/", "") === 'demo'
    
  // ******   SET FOODS, in local state, when in Redux  ******
  useEffect(() => {
    if(debug) console.log("useEffect()... checking if selectedMeal has foods! ==> ", !!selectedMeal.foods)
    if(debug) console.log(`mealId: ${mealId} (${typeof mealId}) | selectedMeal.id: ${selectedMeal.id} ${typeof selectedMeal.id} | mealId === selectedMeal.id: ${mealId === selectedMeal.id } | ${!!selectedMeal.foods} | selectedMeal.foods === null: ${selectedMeal.foods === null}`)

    if(debug) console.log("isNewMeal ==> ", isNewMeal)
    if(debug) console.log("(mealId === 'new') ==> ", mealId === 'new')
    if(debug) console.log("isV2ImageMeal ==> ", isV2ImageMeal)

    // TODO: Cleanup logic....... if URL has mealId or not... then if has mealId, check if it's the same meal as the meal in Redux, if not -> trigger getMeal(mealId)
    // ! OR remove entirely? Cause already have getMeal in other useEffect
    if(mealId && selectedMeal.id && parseInt(mealId) === selectedMeal.id && !!selectedMeal.foods && selectedMeal.apiVersion) {
      // setFoodItems(foods)
      if(debug) console.log("useEffect()... URL has mealId + selectedMeal ID matches URL!   selectedMeal.foods ==> ", selectedMeal.foods)
      if(selectedMeal.entryType === 'text') setMealType('text')
      if(selectedMeal.entryType === 'image') {
        setMealType('image')
        setIsV2ImageMeal(selectedMeal.apiVersion === 'v2')
      }

      setFoodItems(selectedMeal.foods)
    }
    if(isNewMeal && selectedMeal.id && selectedMeal.taskId && selectedMeal.entryType && selectedMeal.createdAt) {
      // if(debug) console.log("useEffect()... URL missing mealId !")
      if(debug) console.log("useEffect()... URL mealId === 'new' !")

      if(selectedMeal.entryType === 'text' && selectedMeal.foods && selectedMeal.foods.length)
      {
        if(debug) console.log("useEffect()... TEXT meal!  selectedMeal.foods ==> ", selectedMeal.foods)
        setMealType('text')
        setFoodItems(selectedMeal.foods)
      }
      // ! TODO: Review if redundant, after updating API to not save `foodComponents` to `foods` until AFTER user initially saves API meal
      else if(selectedMeal.entryType === 'image' && selectedMeal?.foods?.length) {
        if(debug) console.log("useEffect()... IMAGE meal!  selectedMeal.foods ==> ", selectedMeal.foods)
        setMealType('image')
        setIsV2ImageMeal(selectedMeal.apiVersion === 'v2')

        setFoodItems(selectedMeal.foods)
      }
      else if(selectedMeal.entryType === 'image' && selectedMeal.foodComponents && selectedMeal.foodComponents.length) {
        if(debug) console.log("useEffect()... IMAGE meal!  selectedMeal.foodComponents ==> ", selectedMeal.foodComponents)
        setMealType('image')
        setIsV2ImageMeal(selectedMeal.apiVersion === 'v2')

        setFoodItems(selectedMeal.foodComponents)
      }

    }
  }, [selectedMeal.foods, selectedMeal.foodComponents])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if(foodItems) setMealTotals(getMealNutritionTotals(selectedMeal.entryType, selectedMeal.apiVersion === 'v2', foodItems))
    if(debug) console.log("useEffect()... calculating mealTotals ==> ", mealTotals)
  }, [foodItems])

  // Handle logic for when the URL is /meal/demo
  useEffect(() => {
    if(debug) console.log("useEffect()... mealsForSubscribe gating")
    if(debug) console.log("mealsForSubscribe => ", mealsForSubscribe)
      
    const mealCounts = countLargestMealEntryTypes(mealsForSubscribe)
    if(debug) console.log("mealCounts => ", mealCounts)
    
    const tourComplete = localStorage.getItem('tourV2Complete') === 'true'
    
    // Check if to see if user is on /meal/demo
    if(isDemoPage){
      
      // Check if user has already completed tour and/or logged their free image. If so, redirect them.
      if(tourComplete || mealCounts.image > 1){
        if(debug) console.log("***User is attempting to navigate to demo page when they have already logged, redirect***")
        navigate('/meal/new')
      }
      
      // Check to see if selected meal is a meal dummy
      if(!selectedMeal || (selectedMeal && selectedMeal.taskId === 'demo')){
        setIsDummyTourMeal(true)
      }
      else{
        setIsDummyTourMeal(false)
      }
    }
  }, [mealsForSubscribe])
  
  const handleDeleteFood = (index, event) => {
    handleFoodRemoval(foodItems, setFoodItems, index)
  }

  const handleSaveMeal = () => {
    // Dispatch the updateMeal action with the current state of foodItems
    // ! TODO: Check if this is using the user's timezone! If it needs to be sending an offset with the user's timezone deets for converting the DB time on the server-side... or if this is properly generating the UTC-based DB time on the backend.
    updateMeal('save', selectedMeal.id, {foods: foodItems}, navigate)
    
    // Make sure the user can't come back to the demo page if they've logged a real meal
    if(isDemoPage){
      localStorage.setItem('tourV2Complete', 'true')
      navigate('/home?finish_tour=1')
    }
    
  }
  
  // Close out the tour
  const handleFinishTour = () => {
    dispatch(gotMeal({}))
    localStorage.setItem('tourV2Complete', 'true')
    navigate('/chat?finish_tour=1')
  }
  
  const handleDeleteMeal = (index, event) => {
    if(debug) console.log("handleDeleteMeal().... index: ", index)
    setOpenDialog(true)
  }
  const handleConfirmDelete = () => {
    if(debug) console.log("handleConfirmDelete().... ")
    setOpenDialog(false)

    if(debug) console.log("ARCHIVE MEAL... with a tweak!") // ? to note meal freshly ARCHIVED from nutritionAI, NEVER having been saved... need to set foods === null
    // ! TODO: Check if this is using the user's timezone! If it needs to be sending an offset with the user's timezone deets for converting the DB time on the server-side... or if this is properly generating the UTC-based DB time on the backend.
    updateMeal('archive', selectedMeal.id, {archivedAt: DateTime.now(), foods: null}, navigate)
  }
  const handleCancelDelete = () => {
    if(debug) console.log("handleCancelDelete().... ")
    setOpenDialog(false)
  }
  
  const isActive = isUserSubActive(latestUserSub)
// #endregion

  return (
    <Fragment>
      <Tour></Tour>
      {/* //*********    DIALOG MODAL: DELETE Meal      ****** */}
      <ConfirmDialog
        openDialog={openDialog} setOpenDialog={setOpenDialog}
        dialogTitle={"Discard Meal?"}
        contentText={'Are you sure you want to discard this meal?'}
        yesText={'Discard'} yesFunc={handleConfirmDelete}
        noText={'Cancel'} noFunc={handleCancelDelete}  
        />

      {/* //*********    MEAL TOTALS     ****** */}
      <Grid container spacing={2} justifyContent={'center'} sx={{marginTop: 0,}}>

        <Grid item xs={12} sm={8} md={6} >
          <Card elevation={0} sx={{backgroundColor: 'transparent'}}>
            <CardContent>
              <Grid container justifyContent="space-around">

                <Grid item xs={3} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h2" color="primary.main" sx={{fontWeight: 600, textAlign: 'center'}} >
                      Calories
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary.main" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {mealTotals.calories.toFixed(0)}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid item xs={2} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" component="h2" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}} >
                      Protein
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {mealTotals.protein.toFixed(0)}g
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />
                
                <Grid item xs={2} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" component="h2" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}} >
                      Carbs
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {mealTotals.carbs.toFixed(0)}g
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />
                
                <Grid item xs={2} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" component="h2" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}} >
                      Fat
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {mealTotals.fat.toFixed(0)}g
                    </Typography>
                  </Grid>
                </Grid>
                

                {/* <Grid item xs={3} sm={4} container >
                  <Typography color="text.secondary">
                    {mealTotals.calories} cals
                  </Typography>
                </Grid> */}

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* //*********    MEAL IMAGE     ****** */}
      {selectedMeal.imageUrl ? (
        <Grid container justifyContent={'center'} sx={{ marginTop: 0 }}>
          <Grid item xs={12} sm={8} md={6}>
            <Card elevation={0} sx={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
              <CardContent>
                <img
                  src={selectedMeal.imageUrl}
                  alt={selectedMeal.imageTitle}
                  loading="lazy"
                  style={{
                    borderRadius: '0.75rem',
                    borderWidth: '1px',
                    borderColor: 'rgba(86,88,105,1)',
                    maxWidth: '250px', // Maximum width
                    height: 'auto', // Maintains aspect ratio
                    display: 'block', // To center the image
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : null}

      {/* //*********    FOODS     ****** */}
      <Grid container spacing={2} justifyContent={'center'} sx={{marginTop: 0, padding: '0 16px 0 16px'}}>

        {foodItems ? (foodItems.map((item, index) => {
          if(debug) console.log("mealType === ", mealType)
          return (
            <Grid key={index} item xs={12} sm={8} md={6}>
              <Card key={index} elevation={0} sx={{border: "1px solid #00000021"}} >
                {/*     border: 1px solid transparent;
                background-origin: border-box;
                background-clip: padding-box, border-box;
                background-image: linear-gradient(#fef5f5, #fef5f5), linear-gradient(91.04deg, #ffe5d8 17.43%, #ffd7f0 100.12%); */}
                <CardHeader
                  sx={{
                    padding: '16px 16px 0px 16px',
                    '& .MuiCardHeader-action': {
                      marginTop: '-24px',
                      marginRight: '-24px',
                    },
                  }}
                  // avatar={
                  //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  //     R
                  //   </Avatar>
                  // }
                  action={
                    <IconButton aria-label="delete" 
                      sx={{ color: "rgba(0, 0, 0, 0.24)"}}
                      onClick={(event) => handleDeleteFood(index, event)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  title={
                    <Typography variant="h5" component="h2" sx={{fontWeight: 500, fontSize: "100%"}} >
                      {(mealType === 'text' || (mealType === 'image' && selectedMeal.apiVersion === 'v2')) ? item.matched_food_name : item.name}
                    </Typography>
                  }
                  // subheader="September 14, 2016"
                />
                <CardContent>
                  <Grid container>

                    {/* //*********    FOOD INPUT     (Qty)     ****** */}
                    <Grid item xs={3} >
                      <MealFoodInput
                      isTextMeal={mealType === 'text'}
                      isV2ImageMeal={isV2ImageMeal}
                      foodItems={foodItems} setFoodItems={setFoodItems} item={item} index={index} />
                    </Grid>
                    {/* //*********    FOOD SELECTOR    (Units)     ****** */}
                    <Grid item xs={9} >
                      <MealFoodSelector
                        isTextMeal={mealType === 'text'}
                        isV2ImageMeal={isV2ImageMeal}
                        foodItems={foodItems} setFoodItems={setFoodItems} item={item} index={index} />
                    </Grid>

                    {/* //*********    FOOD NUTRITION     (kcals + grams of protein, fat, carbs, fiber)     ****** */}
                    <Grid item xs={12} sm={8} md={6} sx={{ paddingTop: "5px" }} >
                      <MealFoodNutrition 
                        isTextMeal={mealType === 'text'}
                        isV2ImageMeal={isV2ImageMeal}
                        isFood={true} 
                        item={item} index={index}
                        // ! TODO: Check logic on why calories are passed as props but all other info is passed as item of foodItems
                        // TODO: DOCUMENT reason for above 👆🏼^
                        calories={mealType === 'text' || isV2ImageMeal ? item.consumed_calories.toFixed(0) : item.calories} />
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
          })) : null
        }

          <Grid item xs={12} sm={8}>
            <FoodSearch mealId={mealId} />
          </Grid>
        
      </Grid>

      {/* {showAddFoodField && (
        // <Fade in={showAddFoodField} timeout={500}>
          <FoodSearch mealId={mealId} isVisible={showAddFoodField} />
        // </Fade>
      )} */}
      {/* <FoodSearch mealId={mealId} /> */}

       {/* //*********    ADD A FOOD     ****** */}
       <Grid container spacing={2} justifyContent={'center'} sx={{marginTop: 0, padding: '0 16px 0 16px'}}>
          {/* <IconButton color={showAddFoodField ? 'secondary' : 'primary'} onClick={handleAddFoodClick}>
            {showAddFoodField ? (
                <RemoveCircleOutlineIcon />
              ) : (
                <AddCircleOutlineIcon />
              )
            }
          </IconButton> */}

          {/* {showAddFoodField && (
            <Fade in={showAddFoodField} timeout={500}>
              <Grid item>
                <TextField
                  label="Add Food Item"
                  value={foodSearch}
                  onChange={(event) => setFoodSearch(event.target.value)}
                />
              </Grid>
            </Fade>
          )} */}
       </Grid>

      {/* //*********    BUTTONS: Delete & Save     ****** */}
      
      {/* //*********    Regular Meal     ****** */}
      {!isDemoPage && (
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px', marginBottom: '70px' }}>
        {(!mealId || mealId === 'new') && (
          <Grid item>
            <Button 
              variant="contained" 
              color="error" 
              onClick={handleDeleteMeal} 
              startIcon={<CancelIcon />}
              >
              {/* {!mealId ? 'Discard Meal' : 'Update Meal'} */}
              Discard Meal
            </Button>
          </Grid>
        )}      
        <Grid item>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleSaveMeal} 
            startIcon={<SaveIcon />}
            disabled={(disableSave && (!mealId || mealId === 'new'))}
            >
            {(!mealId || mealId === 'new') ? 'Save Meal' : 'Update Meal'}
          </Button>
        </Grid>
      </Grid>
      )}
      
      {/* //*********    Tour: Demo (Fake) Meal     ****** */}
      {isDemoPage && isDummyTourMeal && (
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px', marginBottom: '70px' }}>
        <Grid item>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleFinishTour} 
            startIcon={<SaveIcon />}
            >
            Finish Tour
          </Button>
        </Grid>
      </Grid>
      )}
      
      {/* //*********    Tour: User uploaded a real meal     ****** */}
      {isDemoPage && !isDummyTourMeal && (
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px', marginBottom: '70px' }}>
        {(!mealId || mealId === 'new') && (
          <Grid item>
            <Button 
              variant="contained" 
              color="error" 
              onClick={handleDeleteMeal} 
              startIcon={<CancelIcon />}
              >
              Discard Meal
            </Button>
          </Grid>
        )}      
        <Grid item>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleSaveMeal} 
            startIcon={<SaveIcon />}
            disabled={false}
            >
            Save Meal
          </Button>
        </Grid>
      </Grid>
      )}      
    </Fragment>
  )
}

const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    isMealLoading: state.meals.loading,
    selectedMeal: state.meals.selected,
    foods: state.meals.selected.foods
  }
}
const mapDispatch = (dispatch) => {
  return {
    getMeal: (mealId) => dispatch(getMeal(mealId)),
    updateMeal: (updateType, mealId, theUpdate, navigate) => dispatch(updateMeal(updateType, mealId, theUpdate, navigate)),
    gotMeal: (meal) => dispatch(gotMeal(meal)),
    // TODO: add food search option...
    // extractFoodText: (foodText) => dispatch(extractFoodText(foodText))
  }
}

export default connect(mapState, mapDispatch)(MealFoodGrid)
