import { DateTime } from 'luxon'

let debug = localStorage.getItem("debuggerMode") === 'true'

export const getDateTimeISO = (dateTime = DateTime.now(), returnAllFormats = false, includeTimezone = false) => {
  if(debug) console.log(`getDateTimeISO()... 
  in ALL formats ==> ${returnAllFormats ? 'YUP!' : 'NAW.'}
  with timezone ==> ${includeTimezone ? 'YES!' : 'NOPE.'}
  from dateTime ==> `, dateTime)

  // * Convert the value to ISO format
  const dateTimeISO = dateTime.toISO()
  const plainDateFormat = dateTime.toFormat('yyyy-MM-dd')

  // * Convert value to ISO format && include timezone deets
  const dateTimeISOWithTimezone = dateTime.toISO({ includeOffset: true })

  if(debug) console.log('ISO dateTime:', dateTimeISO)
  if(debug) console.log('The dateTime in plain Date form:', plainDateFormat)
  if(debug) console.log('The dateTime in ISO with Timezone:', dateTimeISOWithTimezone)
  
  if(returnAllFormats) {
    let allFormats = {
      ISO: dateTimeISO,
      ISOWithTimezone: dateTimeISOWithTimezone,
      ISODate: plainDateFormat,
    }
    return allFormats
  }
  else if(includeTimezone) return dateTimeISOWithTimezone
  
  return dateTimeISO
}
export const getMonthStartISO = (dateTime = DateTime.now(), returnAllFormats = false, includeTimezone = false) => {
  if(debug) console.log(`getMonthStartISO()... 
  in ALL formats ==> ${returnAllFormats ? 'YUP!' : 'NAW.'}
  with timezone ==> ${includeTimezone ? 'YES!' : 'NOPE.'}
  from dateTime ==> `, dateTime)

  // * Find the start of the month
  const startOfMonth = dateTime.startOf('month')

  // * Convert the value to ISO format
  const startOfMonthISO = startOfMonth.toISO()
  const plainDateFormat = startOfMonth.toFormat('yyyy-MM-dd')

  // * Convert value to ISO format && include timezone deets
  const startOfMonthISOWithTimezone = startOfMonth.toISO({ includeOffset: true })

  if(debug) console.log('ISO dateTime:', dateTime.toISO())
  if(debug) console.log('Start of the dateTime Month in ISO:', startOfMonthISO)
  if(debug) console.log('Start of the dateTime Month in ISO with Timezone:', startOfMonthISOWithTimezone)
  
  if(returnAllFormats) {
    let allFormats = {
      ISO: startOfMonthISO,
      ISOWithTimezone: startOfMonthISOWithTimezone,
      ISODate: plainDateFormat,
    }
    return allFormats
  }
  else if(includeTimezone) return startOfMonthISOWithTimezone
  
  return startOfMonthISO
}


