import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
import { 
  SubscribeBanner
 } from '../components/index'
import { isUserSubActive } from './subscribe'

let debug = localStorage.getItem("debuggerMode") === 'true'

export function withSubscribeBanner(Component) {
  return function(props) {
    const [timeLeft, setTimeLeft] = useState(null)
    const selectedMealId = useSelector(state => state.meals.selected?.id)
    const latestUserSub = useSelector(state => state.theUser.userSubs.current)
    const selectedMealEntryType = useSelector(state => state.meals.selected?.entryType)
    const isActive = isUserSubActive(latestUserSub)

    useEffect(() => {
      if (isActive || selectedMealEntryType === 'text') return

      const fetchData = async () => {
        if (debug) console.log("useEffect()....get meals in the last 24 hours")

        try {
          const url = selectedMealId
            ? `/api/meals/meals-last-24-hours/image/${selectedMealId}`
            : `/api/meals/meals-last-24-hours/image`

          const { data } = await axios.get(url)
          if (data) {
            if (debug) console.log("got meals in the last 24 hours ==> ", data)

            const minutesAgo = data.minutesAgo
            if (minutesAgo !== null) {
              const remainingMinutes = 1440 - minutesAgo // 1440 minutes in 24 hours
              if (remainingMinutes < 60) {
                setTimeLeft({ minutes: remainingMinutes })
              }
              else {
                const hoursLeft = Math.floor(remainingMinutes / 60)
                setTimeLeft({ hours: hoursLeft })
              }
            }
          }
        }
        catch (error) {
          console.error("Error fetching data: ", error)
        }
      }

      fetchData()
    }, [selectedMealId, isActive, selectedMealEntryType])

    const disableSave = isActive || selectedMealEntryType === 'text' ? false : timeLeft ? (timeLeft.minutes !== undefined ? timeLeft.minutes > 0 : timeLeft.hours > 0) : false

    return (
      <Fragment>
        {!isActive && selectedMealEntryType !== 'text' && <SubscribeBanner timeLeft={timeLeft} />}
        <Component {...props} disableSave={disableSave} />
      </Fragment>
    )
  }
}
