import { combineReducers } from 'redux'
import usersReducer from './users'

//! EXTRA REDUCERS (imports) GO HERE !
import theUserReducer from './theUser'
import mealsReducer from './meals'
import subscriptionsReducer from './subscriptions'

const appReducer = combineReducers({
  users: usersReducer,
  //! EXTRA REDUCERS GO HERE !
  theUser: theUserReducer,
  meals: mealsReducer,
  subscriptions: subscriptionsReducer
})

export default appReducer
