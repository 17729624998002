import { DateTime } from 'luxon'

let debug = localStorage.getItem("debuggerMode") === 'true'

export const matchSubsToTiers = (activeSubs) => {
  if(debug) console.log("matchSubsToTiers()... activeSubs ==> ", activeSubs)

  const tiers = {
    L2: {
      subscriptionType: 'L2',
      title: 'Plus',
      subheader: 'Most popular',
      // price: '14.99',
      // monthlyPrice: `$${monthlyPrice}`,
      description: [
        // '5 day free trial',
        // // '6 images/day',
        // `${imageCap} images/${imageCapIntervalDays === 1 ? 'day' : `${imageCapIntervalDays} days`}`,
        'Unlimited text searching',
        'Early access to features',
        'Priority email support',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
    },
  }

  let matchedSubsTiers = []

  activeSubs.map((sub) => {
    if(debug) console.log("sub => ", sub)
    if(tiers[sub.type]) {
      if(debug) console.log(`The sub (${sub.type}) has a matching tier! `)
      let combinedDeets = {
        ...tiers[sub.type],
        monthlyPrice: `$${sub.monthlyPrice}`,
        stripePriceId: `${sub.stripePriceId}`,
        description: [
          '5 day free trial',
          `${sub.imageCap} images/${sub.imageCapIntervalDays === 1 ? 'day' : `${sub.imageCapIntervalDays} days`}`,
          ...tiers[sub.type].description
        ]
      }
      matchedSubsTiers.push(combinedDeets)
    }
  })
  if(debug) console.log("matchedSubsTiers ==> ", matchedSubsTiers)

  return matchedSubsTiers
}

export const handleStartStripeCheckout = (selected, setSelection, userEmail, navigate) => {
  // setSelectedPrice(monthlyPrice)
  if(debug) console.log("selected subscription: ", selected)
  setSelection(selected)

  if(debug) console.log("creating checkout session")
  fetch('/api/services/stripe/create-checkout-session', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      stripePriceId: selected.stripePriceId,
      amount: selected.monthlyPrice,
      billingFrequency: selected.billingFrequency,
      userEmail: userEmail
     }),
  })
  .then(response => response.json())
  .then(data => {
    if(debug) console.log("client secret retrieved => ", data)
    navigate('/checkout', { state: { clientSecret: data.clientSecret, stripeApiKey: data.stripeApiKey } })
  })
  .catch(error => {
    if(debug) console.log("error creating checkout session ==> ", error)
  })
}


export const isUserSubActive = (userSub) => {
  // Check to see if something is just an empty {}...why this doesn't just evaluate to false in JS, idk...
  function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
  
  // If the user has never had a sub, don't bother evaluating the date
  if(isEmpty(userSub)) {
    if(debug) console.log('userSub is empty')
    return false
  }
  
  // Otherwise, return whether or not the userSub's end date is in the future
  return DateTime.fromISO(userSub.endDate) > DateTime.now()
}
