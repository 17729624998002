import { useState, useEffect, useRef, Fragment, memo } from 'react'
import { Box } from '@mui/material'
import { Button } from '@mui/material'

const TypingText = ({ steps, typingSpeed, setChatImage, showTourImages, onTypingComplete, showPWAInstallInstructions }) => {
  const [displayedText, setDisplayedText] = useState([])
  const charIndexRef = useRef(0)
  const currentStepIndexRef = useRef(0)
  const intervalIdRef = useRef(null)
  const [displayImages, setDisplayImages] = useState(false)
  const [isTypingComplete, setIsTypingComplete] = useState(false)
  const [installCopyTitle, setInstallCopyTitle] = useState('')

  const images = [
    { id: 'demomeal1', url: 'https://aimme-cdn.s3.us-east-1.amazonaws.com/meal_images/e11b9937-4090-4773-a20e-8308509135c3.jpeg' },
    { id: 'demomeal2', url: 'https://aimme-cdn.s3.us-east-1.amazonaws.com/meal_images/4e7a79cc-075d-4a6d-a50d-c833eb13de75.jpg' },
    { id: 'demomeal3', url: 'https://aimme-cdn.s3.us-east-1.amazonaws.com/meal_images/6ab53fc1-1a3e-4f9f-a5e1-2243b0dce88c.jpeg' },
    { id: 'demomeal4', url: 'https://aimme-cdn.s3.us-east-1.amazonaws.com/meal_images/d2a70055-bd7e-4f14-b8ff-fcd02a455362.jpg' }
  ]

  useEffect(() => {
    // Reset state when steps change
    
    if (!steps || steps.length === 0) {
      console.error('Steps are not defined or empty', steps)
      return
    }
        
    setDisplayedText([])
    charIndexRef.current = 0
    currentStepIndexRef.current = 0

    const typeText = () => {
      const currentText = steps[currentStepIndexRef.current]
      if (!currentText) return // Ensure currentText is defined

      if (charIndexRef.current < currentText.length) {
        setDisplayedText(prev => {
          const newDisplayedText = [...prev]
          newDisplayedText[currentStepIndexRef.current] = (newDisplayedText[currentStepIndexRef.current] || '') + currentText.charAt(charIndexRef.current)
          return newDisplayedText
        })
        charIndexRef.current += 1
      } else {
        if (currentStepIndexRef.current < steps.length - 1) {
          charIndexRef.current = 0
          currentStepIndexRef.current += 1
        } else {
          setDisplayImages(true)
          onTypingComplete()
          setIsTypingComplete(true)
          console.log('currentStepIndexRef.current => ', currentStepIndexRef.current)
          console.log('steps.length => ', steps.length)              
          console.log('Typing completed, clearing interval.')
          clearInterval(intervalIdRef.current) // Clear interval after typing is complete
        }
      }
    }

    console.log('Starting typing effect...')
    intervalIdRef.current = setInterval(typeText, typingSpeed)

    return () => {
      console.log('Clearing interval on unmount.')
      clearInterval(intervalIdRef.current)      
    }
  }, [steps, typingSpeed, onTypingComplete])

  const handleImageClick = (id, url) => {
    console.log('Image clicked:', id)
    setChatImage({ URL: url, title: id })
  }
  
  return (
    <Fragment>
      {displayedText.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
      {isTypingComplete && showPWAInstallInstructions && (
        <div                   
          style={{
          textAlign: 'center'
        }}>
        <Button className="progressier-install-button" data-icons="false" data-install="Install App" data-installed="Launch App" color="secondary" background="primary"></Button>
        </div>
      )}
      {showTourImages && displayImages && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1 }}>
            {images.map((image) => (
              <Box
                key={image.id}
                sx={{
                  width: '100px', // Set fixed width
                  height: '100px', // Set fixed height
                  overflow: 'hidden',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => handleImageClick(image.id, image.url)}
              >
                <img
                  src={image.url}
                  alt={`Meal ${image.id}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensure the image covers the box
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

export default memo(TypingText)
