import { Fragment, useState } from 'react'
import { connect, useSelector } from 'react-redux'


import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'

import { DateTime } from 'luxon'

import { HighlightDay } from '../index'

let debug = localStorage.getItem("debuggerMode") === 'true'


const MealCalendar = (props) => {
  if(debug) console.log("MealCalendar PROPS: ", props)
  const {
    // & Redux state PROPS

    // & passed in as PROPS
    selectedDate,
    // selectedMonth,
    mealDates, onSelectDate, onMonthChange
  } = props


  // const [selectedDate, setSelectedDate] = useState(DateTime.now())

  const isMealDate = (date) => {
    // Convert Luxon DateTime to ISO date string
    const isoDate = date.toISODate()

    // console.log("mealDates[isoDate] ==> ", mealDates[isoDate])
    // console.log("!!mealDates[isoDate] ==> ", !!mealDates[isoDate])
    // return mealDates.includes(isoDate)

    return mealDates[isoDate]
  }

  const handleDateChange = (date) => {
    // console.log("handleDateChange => ", date.toISODate())
    // setSelectedDate(date)
    onSelectDate(date.toISODate()) // Pass ISO date string to onSelectDate
  }
  const handleMonthChange = (date) => {
    // console.log("handleMonthChange => ", date)
    // console.log("handleMonthChange... date.toISODate() => ", date.toISODate())
    // setSelectedMonth(date)
    onMonthChange(date.toISODate()) // Pass ISO date string to onSelectDate
  }

  return (
    <Fragment>
      {mealDates ? (
        <DateCalendar
          date={selectedDate}
          // value={selectedMonth}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          // sx={{
          //   '& .MuiDayCalendar-weekContainer': {paddingTop: '5px'}
          // }}
          slots={{
            day: HighlightDay,
          }}
          slotProps={{
            day: {
              isMealDate,
            },
          }}
        />
      ) : null}
    </Fragment>
  )
}

// // const DayComponent = ({ day, isMealDay, ...other }) => {
// export const DayComponent = ({ day, isMealDate, ...other }) => {
//   // if(debug) console.log("DayComponent PROPS: ", props)
//   if(debug) console.log("DayComponent PROPS: ", day, isMealDate, other)
//   return (
//     <Badge overlap="circular" badgeContent={isMealDate ? '🍽️' : undefined}>
//       <PickersDay {...other} />
//     </Badge>
//   )
// }


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    // isMealLoading: state.meals.loading,
    // selectedMeal: state.meals.selected,
    // foods: state.meals.selected.foods
  }
}
const mapDispatch = (dispatch) => {
  return {
    // getMeal: (mealId) => dispatch(getMeal(mealId)),
  }
}

export default connect(mapState, mapDispatch)(MealCalendar)
