import { Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import {  searchFoodItems, selectFoodItem, updatedMeal, searchedFoodItems } from '../../redux/meals'

// ******     MUI      ******
import { 
  Grid,
  Card, CardHeader, CardContent,
  Autocomplete, TextField, CircularProgress,
  IconButton,
  Typography,
  // Divider,
  Fade,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import SearchIcon from '@mui/icons-material/Search'


// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'



const FoodSearch = (props) => {
  if(debug) console.log("FoodSearch PROPS: ", props)
  const {
    // & Redux state PROPS
    theUser,
    // & passed in as PROPS
    mealId,
  } = props

  // *** STATES
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const searchResults = useSelector(state => state.meals.searchResults) // Assuming searchResults is part of your meals state
  const [options, setOptions] = useState([])
  const loading = open && searchResults.length === 0
  const [value, setValue] = useState('')

  const [showAddFoodField, setShowAddFoodField] = useState(false)

  const handleAddFoodClick = () => {
    setShowAddFoodField(!showAddFoodField)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if(debug) console.log("useEffect().... searchFoodItems() IF inputValue ==> ", inputValue)
    if (inputValue) {
      dispatch(searchFoodItems(inputValue))
    }
    else {
      dispatch(searchedFoodItems([]))
    }
  }, [inputValue, dispatch])

  useEffect(() => {
    if(debug) console.log("useEffect().... setOptions() IF searchResults ==> ", searchResults)
    if(debug) console.log("checking if searchResults?.length ==> ", searchResults?.length)
    if (searchResults?.length) {
      setOptions(searchResults)
    }
  }, [searchResults])

  useEffect(() => {
    if(debug) console.log("useEffect().... setOptions() IF !open ==> ", options)
    if (!open) {
      setOptions([])
    }
  }, [open])

  const handleInputChange = (event, newInputValue, reason) => {
    if(debug) console.log("handleInputChange()....  newInputValue ==> ", newInputValue)
    if(debug) console.log(`handleInputChange()... reason ==> `, reason)
    setInputValue(newInputValue)
  }

  const handleChange = (event, newValue, reason) => {
    // ? CAUSE Dick's code is...
    // data.forEach(function(item) {
    //   resultsHtml += `<div class="autocomplete-item" data-id="${item.id}">${item.name}</div>`;
    // });
    if(debug) console.log(`handleChange()... reason ==> `, reason)
    if(reason === 'clear') {
      if(debug) console.log("handleChange()... newValue ==> ", newValue)
      if(debug) console.log(`SELECTED FOOD (ID #${newValue?.id ? newValue.id : null}).... name ==> `, newValue?.name ? newValue.name : null)
      setInputValue('')
      // setValue(newValue)
      setValue('')
      dispatch(searchedFoodItems([]))
    }
    else if(reason === 'selectOption') {
      if(debug) console.log(`SELECTED FOOD (ID #${newValue.id ? newValue.id : null}).... name ==> `, newValue?.name ? newValue.name : null)
      if(newValue?.id) dispatch(selectFoodItem(newValue.id))
      setInputValue('')
      setValue('')
      dispatch(searchedFoodItems([]))
    }
    else {
      if(debug) console.log(`SELECTED FOOD (ID #${newValue.id ? newValue.id : null}).... name ==> `, newValue?.name ? newValue.name : null)
      if(newValue?.id) dispatch(selectFoodItem(newValue.id))
      // *** Additional logic after selection can be added here
      // ! TODO: After get the response for selected food and it's added into Redux store... what then? SHOULD ALREADY auto-update the meal view. Confirm!
      // TODO: Hide search input again or...?
      setInputValue('')
      // setValue(newValue)
      setValue('')
      dispatch(searchedFoodItems([]))
    }
    
  }


  return (
    <Fragment>
      <Fade in={showAddFoodField} timeout={500} mountOnEnter unmountOnExit>
        <Card elevation={0} sx={{border: "1px solid #00000021"}} >
          <Grid container 
            justify="center"
            spacing={3}
            alignContent="center"
            justifyContent="center"
            marginTop="10px"
            >
            <Grid item xs={12} sm={10} md={8} lg={10}>
              <Typography variant="h3" sx={{textAlign: 'center'}}>
                FOOD SEARCH
              </Typography>
            </Grid>

            {/* <Grid item xs={12} sm={10} md={8} lg={10}>
              <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
              <div>{`inputValue: '${inputValue}'`}</div>
            </Grid> */}

            <Grid item sx={{pb:2}}>
              <Autocomplete
                id="food-search"
                style={{ width: 300 }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                // selectOnFocus
                // clearOnBlur
                handleHomeEndKeys
                filterOptions={(x) => x}
                options={searchResults}
                // options={options ?? []}
                value={value}
                inputValue={inputValue}
                freeSolo

                // renderTags={(value, getTagProps, ownerState) => {
                //   console.log("renderTags()...")
                //   console.log("renderTags()...   value ==> ", value)
                //   console.log("renderTags()...   getTagProps() ==> ", getTagProps())
                //   console.log("renderTags()...   ownerState ==> ", ownerState)
                // }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                loading={loading}
                getOptionLabel={(option) => {
                  // console.log("getOptionLabel()... option ==> ", option)
                  if(option.name) return option.name
                  else return ''
                }}
                getOptionKey={(option) => option.id}
                onInputChange={handleInputChange}
                onChange={handleChange}
                // renderInput={(params) => (
                //   <TextField
                //     {...params}
                //     label="Search food"
                //   />
                // )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search foods..."
                    // helperText="Search foods..."
                    // value={inputValue}
                    InputProps={{
                      ...params.InputProps,
                      // startAdornment: (
                      //   // <InputAdornment position="start">
                      //     <SearchIcon />
                      //   // </InputAdornment>
                      //   // {params.InputProps.startAdornment}
                      // ),
                      endAdornment: (
                        <Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  console.log("renderOption().... option ==> ", option)
                  const matches = match(option.name, inputValue, { insideWords: true })
                  const parts = parse(option.name, matches)
                  console.log("parts ==> ", parts)
          
                  return (
                    <li {...props}>
                      {/* // ^ CONSIDER ALTS: [https://blog.logrocket.com/using-dangerouslysetinnerhtml-react-application/#alternative-dangerouslysetinnerhtml] */}
                      {/* <div
                        dangerouslySetInnerHTML={{__html: option.name}}
                      /> */}
                      {/* <div>
                        <span key={option.id}>
                          {option.name}
                        </span>
                      </div> */}
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
            </Grid>

            {/* <Grid item xs={12} sm={8} md={6} lg={4}>
              <Paper 
                spacing={2}
                sx={{
                  flexGrow: 1,
                  textAlign: 'center',
                  display: 'flex', flexDirection: 'column',
                  alignItems: 'center'
                }}
                >
                <DailyNutritionTotals meals={mealsToday} />
              </Paper>
            </Grid> */}

          </Grid>
        </Card>
      </Fade>

      <Grid container 
        justify="center"
        // spacing={3}
        alignContent="center"
        justifyContent="center"
        marginTop="0px"
        >
        <Grid item>
          <IconButton color={showAddFoodField ? 'secondary' : 'primary'} onClick={handleAddFoodClick}>
            {showAddFoodField ? (
                <RemoveCircleOutlineIcon />
              ) : (
                <AddCircleOutlineIcon />
              )
            }
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    theUser: state.theUser,
  }
}

const mapDispatch = (dispatch) => {
  return {
    // logout: () => dispatch(logout()),
  }
}

export default connect(mapState, mapDispatch)(FoodSearch)
