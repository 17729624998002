import { useState } from 'react'
import { connect } from 'react-redux'

import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
// import FormHelperText from '@mui/material/FormHelperText'

import { handleQuantityChange } from '../../utils/nutrition'

let debug = localStorage.getItem("debuggerMode") === 'true'


const MealFoodInput = (props) => {
  if(debug) console.log("MealFoodInput PROPS: ", props)
  const {
    // & Redux PROPS
    isMealLoading,
    // & passed in as PROPS
    isTextMeal,
    isV2ImageMeal,
    foodItems, setFoodItems, // useState() in parent
    item, index, // foodItems.map(item, index) => 
  } = props

  const [error, setError ] = useState({
    status: false,
    message: '',
  })

  const handleInputChange = (index, event) => {
    const newQuantity = event.target.value
    const periodIndex = newQuantity.indexOf('.')

    if(debug) console.log(`newQuantity: ${newQuantity} | periodIndex: ${periodIndex} | newQuantity.length: ${newQuantity.length}`)

    if(!parseFloat(newQuantity) || periodIndex === newQuantity.length-1) {
      setError({status: true, message: 'Enter valid number.'})
    }
    else {
      setError({status: false, message: ''})
      handleQuantityChange(foodItems, setFoodItems, index, newQuantity)
    }
  }


  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
    >
      <FormControl 
        size="small"
        error={error.status}
        disabled={!isTextMeal && !isV2ImageMeal}
        sx={{
          m: 1,
          // width: '8ch' 
          minWidth: '6ch',
          maxWidth: '15ch',
         }}>
        <InputLabel htmlFor="quantity-label">Qty</InputLabel>
        <OutlinedInput
          id="quantity-label"
          defaultValue={isTextMeal || isV2ImageMeal ? item.consumed_quantity : item.quantity}
          label="Qty"
          onChange={(event) => handleInputChange(index, event)}
          // aria-describedby="quantity-text"
        />
        {/* <FormHelperText id="quantity-text">{error.message}</FormHelperText> */}

      </FormControl>
    </Box>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    isMealLoading: state.meals.loading,
  }
}
const mapDispatch = (dispatch) => {
  return {

  }
}

export default connect(mapState, mapDispatch)(MealFoodInput)
