export const countLargestMealEntryTypes = (meals) => {
    const totalCounts = {
        image: 0,
        text: 0,
        imageToText: 0
    };

    if (meals && Array.isArray(meals)) {
        meals.forEach(meal => {
            const mealCount = Number(meal.mealCount);

            if (meal.entryType === 'image') {
                totalCounts.image += mealCount;
            } else if (meal.entryType === 'text') {
                totalCounts.text = mealCount;
            } else if (meal.entryType === 'imageToText') {
                totalCounts.imageToText = mealCount;
            }
            // Add additional else if conditions for other entry types if needed
        });
    }

    return totalCounts;
}
