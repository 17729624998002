import { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { useParams, useNavigate } from 'react-router-dom'

import { 
  getMeals,
  updateMeal
 } from '../../redux/meals'

import { 
  Grid, Box, 
  Card, CardHeader, CardContent,
  IconButton, Button, Chip,
  Typography,
  Divider,
} from '@mui/material'
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
// import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
// import DeleteIcon from '@mui/icons-material/Delete'
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'

import { ConfirmDialog, MealFoodNutrition } from '../index'

import { getDayNutritionTotals } from '../../utils/nutrition'

import { DateTime } from 'luxon'


let debug = localStorage.getItem("debuggerMode") === 'true'


const MealsGrid = (props) => {
  if(debug) console.log("MealsGrid PROPS: ", props)
  const {
    // & Redux PROPS
    // isMealLoading,
    // selectedMeal, foods,
    selectedMeals,
    getMeals,
    updateMeal
    // & passed in as PROPS

  } = props

// //#region ******    HOOKS & FUNCS    ******
  let { mealsDate } = useParams()
  if(debug) console.log("useParams()... mealsDate => ", mealsDate)

  // ******   GET MEAL, if necessary, from URL  ******
  useEffect(() => {
    if(mealsDate) {
      if(debug) console.log("mealsDate ==> ", mealsDate)

      let mealsDateISOWithOffset = DateTime.fromISO(mealsDate).toISO({ includeOffset: true })
      if(debug) console.log("mealsDateISOWithOffset ==> ", mealsDateISOWithOffset)

      getMeals(mealsDateISOWithOffset, 'day')
    }
  }, [])

  const [meals, setMeals] = useState([])
  const [dayTotals, setDayTotals] = useState({
    calories: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
    fiber: 0,
    // *** for DOUBLE-CHECKING calcs ***
    kcals: 0,
  })

  const [openDialog, setOpenDialog] = useState(false)
  const [deleteMealId, setDeleteMealId] = useState(null)

  // ******   SET MEALS, in local state, when in Redux  ******
  useEffect(() => {
    if(debug) console.log("useEffect()... checking if day has selectedMeals! ==> ", !!meals)
    if(!!selectedMeals) {
      setMeals(selectedMeals)
    }
  }, [selectedMeals])

  const navigate = useNavigate()

  useEffect(() => {
    if(meals) setDayTotals(getDayNutritionTotals(meals))
    if(debug) console.log("useEffect()... calculating dayTotals ==> ", dayTotals)
  }, [meals])

  useEffect(() => {
    if(debug) console.log("useEffect()... deleteMealId ==> ", deleteMealId)
  }, [deleteMealId])


  const handleDeleteMeal = (index, event) => {
    if(debug) console.log("handleDeleteMeal().... index: ", index)
    setOpenDialog(true)

    setDeleteMealId(meals[index].id)
  }
  const handleConfirmDelete = () => {
    if(debug) console.log("handleConfirmDelete().... ")
    setOpenDialog(false)
    // handleFoodRemoval(foodItems, setFoodItems, deleteMealId)
    // ! TODO: Check if this is using the user's timezone! If it needs to be sending an offset with the user's timezone deets for converting the DB time on the server-side... or if this is properly generating the UTC-based DB time on the backend.
    updateMeal('archive', deleteMealId, {archivedAt: DateTime.now()}, navigate)
    setDeleteMealId(null)
  }
  const handleCancelDelete = () => {
    if(debug) console.log("handleCancelDelete().... ")
    setOpenDialog(false)
    setDeleteMealId(null)
  }

  const handleEditMeal = (index, event) => {
    if(debug) console.log("handleEditMeal().... index: ", index)
    navigate(`/meal/${meals[index].id}`)
  }

// #endregion

  return (
    <Fragment>
      {/* //*********    DIALOG MODAL: DELETE Meal      ****** */}
      <ConfirmDialog
        openDialog={openDialog} setOpenDialog={setOpenDialog}
        dialogTitle={"Delete Meal?"}
        contentText={'Are you sure you want to delete this meal?'}
        yesText={'Delete'} yesFunc={handleConfirmDelete}
        noText={'Cancel'} noFunc={handleCancelDelete}  
        />
      {/* //*********    DAY TOTALS     ****** */}
      <Grid container spacing={2} justifyContent={'center'} sx={{marginTop: 0,}}>

        <Grid item xs={12} sm={8} md={6} >
          <Card elevation={0} sx={{backgroundColor: 'transparent'}}>
            <CardContent>
              <Grid container justifyContent="space-around">

                <Grid item xs={3} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h2" color="primary.main" sx={{fontWeight: 600, textAlign: 'center'}} >
                      Calories
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary.main" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {dayTotals.calories.toFixed(0)}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid item xs={2} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" component="h2" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}} >
                      Protein
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {dayTotals.protein.toFixed(0)}g
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />
                
                <Grid item xs={2} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" component="h2" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}} >
                      Carbs
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {dayTotals.carbs.toFixed(0)}g
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />
                
                <Grid item xs={2} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" component="h2" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}} >
                      Fat
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 500, textAlign: 'center'}}>
                      {dayTotals.fat.toFixed(0)}g
                    </Typography>
                  </Grid>
                </Grid>
                

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* //*********    MEALS     ****** */}
      <Grid container spacing={2} justifyContent={'center'} sx={{marginTop: 0, padding: '0 16px 0 16px'}}>

        {meals.map((meal, index) => {
          if(debug) console.log("meal ==> ", meal)

          let mealType = meal.entryType
          let isV2ImageMeal = meal?.apiVersion && (mealType === 'image') ? meal.apiVersion === 'v2' : null

          return (
            <Grid key={index} item xs={12} sm={8} md={6}>
              <Card key={index} elevation={0} sx={{border: "1px solid #00000021"}} >
                <CardHeader
                  sx={{
                    padding: '16px 16px 0px 16px',
                    '& .MuiCardHeader-action': {
                      marginTop: '-24px',
                      marginRight: '-24px',
                    },
                  }}
                  // avatar={
                  //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  //     R
                  //   </Avatar>
                  // }
                  action={
                    <IconButton aria-label="delete" 
                      sx={{ 
                        color: "rgba(0, 0, 0, 0.24)",
                        ':hover': { 
                          color: "rgb(255 1 1 / 62%)",
                          backgroundColor: 'rgb(255 1 1 / 4%)'
                        }
                      }}
                      onClick={(event) => handleDeleteMeal(index, event)}
                    >
                      <CloseIcon />
                      {/* <DeleteIcon /> */}
                      {/* <DeleteOutlineIcon /> */}
                    </IconButton>
                  }
                  title={
                    <Fragment>
                      <Typography variant="h5" component="h2" sx={{fontWeight: 500, fontSize: "100%", display: 'inline-flex'}} >
                        {`${DateTime.fromISO(meal.dateConsumed).toFormat('t')}`}
                      </Typography>
                      <IconButton aria-label="delete" 
                        sx={{ 
                          color: "rgba(0, 0, 0, 0.24)",
                          ':hover': { 
                            color: "rgb(13 215 0 / 62%)",
                            backgroundColor: 'rgb(13 215 0 / 4%)'
                          },
                          paddingTop: '0px',
                        }}
                        onClick={(event) => handleEditMeal(index, event)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Fragment>
                  }
                />
                <CardContent>
                  <Grid container>

                    <Grid item xs={12} >
                      {meal.foods ? (meal.foods.map((food, foodIndex) => {
                        if(debug) console.log("mealType ==> ", mealType)
                        let isTextMeal = mealType === 'text'
                        if(debug) console.log("isTextMeal ==> ", isTextMeal)
                        if(debug) console.log("isV2ImageMeal ==> ", isV2ImageMeal)
                        if(debug) console.log("food ==> ", food)
                        return (
                          <Fragment key={foodIndex} >
                              <Typography variant="body1" component="h3" sx={{fontWeight: 400, fontSize: "100%"}} >
                                {isTextMeal || isV2ImageMeal ? `${food.matched_food_name} (${food.consumed_quantity} ${food.consumed_unit})` : `${food.name} (${food.quantity} ${food.unit})`}
                              </Typography>
                          </Fragment>
                        )
                      })) : meal.foodComponents ? (meal.foodComponents.map((food, foodIndex) => {
                        let isTextMeal = mealType === 'text'
                        if(debug) console.log("isTextMeal ==> ", isTextMeal)
                        return (
                          <Fragment key={foodIndex} >
                              <Typography variant="body1" component="h3" sx={{fontWeight: 400, fontSize: "100%"}} >
                                {isTextMeal || isV2ImageMeal ? `${food.matched_food_name} (${food.consumed_quantity} ${food.consumed_unit})` : `${food.name} (${food.quantity} ${food.unit})`}
                              </Typography>
                          </Fragment>
                        )
                      })) : null}
                    </Grid>

                    <Grid item xs={12} md={8} sx={{ paddingTop: "16px" }} >
                      <MealFoodNutrition 
                        isTextMeal={mealType === 'text'} 
                        isV2ImageMeal={isV2ImageMeal}
                        isFood={false} 
                        item={meal} 
                        index={index}
                        calories={Number(meal.calories).toFixed(0)}
                        />
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      
      </Grid>

      {/* <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleSaveMeal} 
            startIcon={<SaveIcon />}
            >
            {!mealId ? 'Save Meal' : 'Update Meal'}
          </Button>
        </Grid>
      </Grid> */}

    </Fragment>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    // isMealLoading: state.meals.loading,
    // meals: state.meals.all
    selectedMeals: state.meals.forCalendar.selected,
  }
}
const mapDispatch = (dispatch) => {
  return {
    getMeals: (dateTime, interval) => dispatch(getMeals(dateTime, interval)),
    updateMeal: (updateType, mealId, theUpdate, navigate) => dispatch(updateMeal(updateType, mealId, theUpdate, navigate))
  }
}

export default connect(mapState, mapDispatch)(MealsGrid)
