
import Avatar from '@mui/material/Avatar'

import { extractNamesAndInitials } from '../../utils/extractNamesAndInitials'

let debug = localStorage.getItem("debuggerMode") === 'true'


function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(fullName) {
  return {
    sx: {
      bgcolor: stringToColor(fullName),
    },
    children: extractNamesAndInitials(fullName).initialsFirstAndLast,
  }
}

export default function ColoredLetterAvatar(props) {
  if(debug) console.log("ColoredLetterAvatar PROPS: ", props)
  const {
    fullName,
    size,
    sx
  } = props

  let combinedAvatarProps = {
    ...stringAvatar(fullName),
    sx: {
      ...stringAvatar(fullName).sx,
      ...size && { ...size },
      ...sx && {...sx}
    }}
    if(debug) console.log("combinedAvatarProps => ", combinedAvatarProps)

  return (
    <Avatar {...combinedAvatarProps} />
  )
}

