import { connect } from 'react-redux'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import { handleUnitChange } from '../../utils/nutrition'

let debug = localStorage.getItem("debuggerMode") === 'true'


const MealFoodSelector = (props) => {
  if(debug) console.log("MealFoodSelector PROPS: ", props)
  const {
    // & Redux PROPS
    isMealLoading,
    // & passed in as PROPS
    isTextMeal,
    isV2ImageMeal,
    foodItems, setFoodItems, // useState() in parent
    item, index, // foodItems.map(item, index) => 
  } = props

  if(debug) console.log("item.unit ==> ", item.unit)

  const handleSelectChange = (index, event) => {
    const newUnit = event.target.value
    handleUnitChange(foodItems, setFoodItems, index, newUnit)
  }


  return (
    <FormControl 
      sx={{ m: 1, minWidth: 120 }}
      size="small"
      disabled={!isTextMeal && !isV2ImageMeal}
      >
      <InputLabel id="units-label">Unit</InputLabel>
      <Select
        labelId="units-label"
        id="units"
        value={isTextMeal || isV2ImageMeal ? item.consumed_unit : item.unit}
        label="Unit"
        onChange={(event) => handleSelectChange(index, event)}
      >
        {isTextMeal || isV2ImageMeal ? Object.keys(item.unit_conversions).map((unit) => (
          <MenuItem key={unit} value={unit}>{unit}</MenuItem>
        )) : <MenuItem value={item.unit}>{item.unit}</MenuItem>}
      </Select>
    </FormControl>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    isMealLoading: state.meals.loading,
  }
}
const mapDispatch = (dispatch) => {
  return {

  }
}

export default connect(mapState, mapDispatch)(MealFoodSelector)
