import { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'

// import { 
//   // getMeals,
//  } from '../../redux/meals'

import { VictoryPie, VictoryLabel } from 'victory'

import {
  Typography, Chip, Grid
} from '@mui/material'

import { getDayNutritionTotals } from '../../utils/nutrition'
import { orange } from '@mui/material/colors'

// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'


// const size = {
//   width: 400,
//   height: 200,
// }


const DailyNutritionTotals = (props) => {
  if(debug) console.log("DailyNutritionTotals PROPS: ", props)
  const {
    // & Redux PROPS
    isMealLoading,
    // & passed in as PROPS
    meals,
    // foodItems, setFoodItems, // useState() in parent
  } = props

  const [todayTotals, setTodayTotals] = useState({
    calories: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
    fiber: 0,
    // *** for DOUBLE-CHECKING calcs ***
    kcals: 0,
  })

  if(debug) console.log("meals....", meals)
  useEffect(() => {
    if(meals) setTodayTotals(getDayNutritionTotals(meals))
    if(debug) console.log("useEffect()... calculating todayTotals ==> ", todayTotals)
  }, [meals])

  return (
    <Fragment>
      <Typography
          variant="h3"
          sx={{ paddingTop: '16px' }}
        >
          Today's Totals
        </Typography>

      <div
        style={{ 
          position: 'relative', padding: '16px',
          paddingBottom: '0px',
          width: '50%'
        }}
        >
        <svg viewBox="0 0 400 400">
          <VictoryPie
            data = {[
              { x: "Protein", y : (((Number(todayTotals.protein) * 4) / todayTotals.calories) * 100) },
              { x: "Carbs", y: (((Number(todayTotals.carbs) * 4) / todayTotals.calories) * 100) },
              { x: "Fat", y: (((Number(todayTotals.fat) * 9) / todayTotals.calories) * 100) },
            ]}
            width={400} height={400}
            standalone={false} // this prop is necessary for labels to work properly in an SVG container
            // colorScale={["blue", "green", "orange"]}
            colorScale={["rgb(144, 1, 203)", "rgba(2, 178, 175, 1)", "orange"]}
            radius={200}
            innerRadius={170}
            cornerRadius={5}
            // startAngle={-90}
            // endAngle={180}
            startAngle={-135}
            endAngle={135}
            padAngle={5}
            // radius={({ datum }) => (datum.y > 50 ? 100 : 80)}
            labels={() => null} // This will remove the labels
          />
          {/* <VictoryLabel
            textAnchor="middle" verticalAnchor="middle"
            x={200} y={200} // adjust to center the label
            text="563 cals"
            style={{ fontSize: 20, fontFamily: 'Raleway, Montserrat, Lato, Nunito Sans, sans-serif' }} // adjust styling as needed
          /> */}
        </svg>

        <Typography
          variant="h4"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {Number(todayTotals.calories).toFixed(0)} cals
        </Typography>
      </div>

      <Grid item xs={12} md={8} sx={{ paddingBottom: "16px" }} >
        <Chip 
          label={`P ${Number(todayTotals.protein).toFixed(0)}g`}
          size="small"
          color="secondary"
          sx={{
            margin: "0px 2px", fontSize: "85%",
            backgroundColor: 'rgb(144, 1, 203)'
          }} 
          />
        <Chip 
          label={`C ${Number(todayTotals.carbs).toFixed(0)}g`}
          size="small"
          color="primary"
          sx={{
            margin: "0px 2px", fontSize: "85%",
            backgroundColor: 'rgba(2, 178, 175, 1)',
          }} 
          />
        <Chip 
          label={`F ${Number(todayTotals.fat).toFixed(0)}g`}
          size="small"
          sx={{
            margin: "0px 2px", fontSize: "85%",
            backgroundColor: orange[300],
          }} 
          />
      </Grid>

    </Fragment>
  )
}

const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    isMealLoading: state.meals.loading,
  }
}
const mapDispatch = (dispatch) => {
  return {
    // getMeals: (dateTime, interval) => dispatch(getMeals(dateTime, interval)),
  }
}

export default connect(mapState, mapDispatch)(DailyNutritionTotals)
