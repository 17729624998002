import { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// import Link from '@mui/material/Link'
import Container from '@mui/material/Container'

import { getSubscriptions } from '../../redux/subscriptions'

import { matchSubsToTiers, handleStartStripeCheckout } from '../../utils/subscribe'

let debug = localStorage.getItem("debuggerMode") === 'true'


const FreeTrialVideo = (props) => {
  if(debug) console.log("FreeTrialVideo PROPS: ", props)
  const {
    // & Redux PROPS

    // & passed in as PROPS

  } = props

  // const [selectedPrice, setSelectedPrice] = useState('')
  const [selection, setSelection] = useState({})
  const [products, setProducts] = useState([])
  const [tier, setTier] = useState(null)

  const dispatch = useDispatch()
  // const navigate = useNavigate()

  const subscriptions = useSelector(state => state.subscriptions.all)
  // const latestUserSub = useSelector(state => state.theUser.userSubs.current)
  const userEmail = useSelector(state => state.theUser.email)
  // const theUser = useSelector(state => state.theUser)

  useEffect(() => {
    dispatch(getSubscriptions())
  }, [])
  
  useEffect(() => {
    if(subscriptions) {
      let availProducts = matchSubsToTiers(subscriptions)
      if(debug) console.log("useEffect()... availProducts ==> ", availProducts)
      setProducts(availProducts)
      setTier(availProducts[0])
      if(debug) console.log("tier ==> ", tier)
    }
  }, [subscriptions])


  return (
      <Container maxWidth="md" component="main">
        <Box sx={{ marginTop: 5, textAlign: 'center' }}>        
          <Typography
            component="h4"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Watch How AIMme Works
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 250, my: 2, marginTop: 5, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <video
              width="100%"
              height="auto"
              controls
              autoPlay
              muted
              loop
              style={{ maxWidth: '100%', height: 'auto' }} // Ensures responsiveness
            >
              <source src="https://aimme-cdn.s3.us-east-1.amazonaws.com/example_videos/AIMme%20Demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>

          <Grid container justifyContent="center">
            <Button type="submit" variant="contained" size="large" sx={{ mt: 2, mb: 3 }}
              onClick={() => {
                let selected = {
                  // tier.monthlyPrice
                  stripePriceId: tier.stripePriceId,
                  billingFrequency: 'trial',
                  selectedPrice: tier.monthlyPrice,
                }
                handleStartStripeCheckout(selected, setSelection, userEmail)
              }}
              >
              Continue to Free Trial
            </Button>
          </Grid>
        </Box>
      </Container>
  )
}

export default FreeTrialVideo
