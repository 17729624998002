import axios from 'axios'

// *** ACTION TYPES
const GOT_USERS = 'GOT_USERS'
import { LOGGED_OUT } from './theUser'

// *** ACTION CREATORS
export const gotUsers = (users) => ({
    type: GOT_USERS,
    users
})

// *** THUNK CREATORS
export const getUsers = () => {
    return async (dispatch) => {
        try {
            const {data} = await axios.get('/api/users')
            dispatch(gotUsers(data))
        } catch (error) {
            console.error('Whoops, trouble getting users!')
        }
    }
}

// *** INITIAL STATE
const initialState = {
    all: [],
    loading: true
}

// *** REDUCER
const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GOT_USERS:
            return {...state, all: action.users, loading: false}
        case LOGGED_OUT:
          console.log("LOGGED_OUT (users) => ", action.reducers)
          if(action.reducers.includes('users')) {
            console.log("users reducer is included (in action)... return initialState!")
            return {...initialState, loading: false}
          }
          else return state
          // return {...initialState, loading: false}
        default:
            return state
    }
}

export default usersReducer
