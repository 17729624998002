import { loadStripe } from '@stripe/stripe-js'
import { useLocation } from 'react-router-dom'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'


let debug = localStorage.getItem("debuggerMode") === 'true'


const Checkout = () => {
  const location = useLocation()
  const clientSecret = location.state?.clientSecret
  const stripeApiKey = location.state?.stripeApiKey
  
  const stripePromise = loadStripe(stripeApiKey)

  return (
    <Container component="main" maxWidth="lg"> 
      <Box sx={{ marginTop: 8, textAlign: 'center' }}>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Start Your 5-day Free Trial 📷 🙌
        </Typography>    
        <Typography variant="h5" align="center" color="text.secondary">
          Put your nutritional tracking on autopilot.
        </Typography>

        <Grid item xs={12} sx={{ marginTop: 5 }}> 
          {clientSecret && (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default Checkout
