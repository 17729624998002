
export const getUpdatedAll = (array, newElement) => {
  let updatedAll = []
  let foundElement = false
  
  // safe to use .map() or other array methods
  if (array && Array.isArray(array)) {    
    array.map((element) => {
      if(element.id === newElement.id) {
        foundElement = true
        // console.log("Check if newElement is ARCHIVED.... newElement.archivedAt ==> ", newElement.archivedAt)
        if(!newElement.archivedAt) {
          // console.log("NOT archived... push into rebuilt array.")
          updatedAll.push(newElement)
        }
      }
      else updatedAll.push(element)
    })  
  }
  if (!foundElement) updatedAll.push(newElement)
  console.log("Updated array for reducer => ", updatedAll)

  return updatedAll
}
