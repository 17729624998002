import { useState, useEffect, useRef, Fragment, memo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TypingText from './TypingText'
import { userMealAnalysis, demoMealAnalysis, endingDashboardTourSteps } from './tourValues'

// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'


const Tour = () => {
  const [steps, setSteps] = useState([])
  const [showTour, setShowTour] = useState(false)
  const [showPWAInstallButton, setShowPWAInstallButton] = useState(false)
  const selectedMeal = useSelector(state => state.meals.selected)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const finishTour = params.get('finish_tour')
  // const tourComplete = localStorage.getItem('tourV2Complete') === 'true'
  if(debug) console.log('finishTour => ', finishTour)

  const startTour = () => {
    setShowTour(true)
  }

  const handleCloseTour = () => {
    setShowTour(false)
  }
  
  useEffect(() => {
    let path = location.pathname
    
    if(path==='/meal/demo'){
      if(debug) console.log("***MealFoodGridTour useEffect called***")    
      if(debug) console.log("selectedMeal => ", selectedMeal)
      
      // Show different message depending on whether or not a real meal was used
      if(selectedMeal.taskId === 'demo'){
        setSteps(demoMealAnalysis)
      }
      else{
        setSteps(userMealAnalysis)
      }
      startTour()
    }
    else if(path==='/home' && finishTour){
      if(debug) console.log('finish tour steps...')      
      setSteps(endingDashboardTourSteps)
      setShowPWAInstallButton(true)
      startTour()
    }              
  }, [])  // Empty dependency array ensures this runs only once

  // useEffect to check for standalone mode, which will close out tour and not annoy user
  useEffect(() => {
    const checkStandaloneMode = () => {
      const isStandalone = document.querySelector('body').classList.contains('progressier-standalone')
      if (isStandalone) {
        localStorage.setItem('tourV2Complete', 'true')
        handleCloseTour()
      }
    }

    // Check initially
    checkStandaloneMode()

    // Add a delay to check again, as there might be a slight delay before the class is added
    const timeoutId = setTimeout(checkStandaloneMode, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])  
    
  
  const handleTypingComplete = () => {
    // Handle what happens when typing is complete
    if(debug) console.log('Typing complete')
  }
    
  return (
    <Fragment>
      {showTour && (
        <div className="speech-bubble">
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleCloseTour}
            size="small"
          >
            <CloseIcon />
          </IconButton>          
          <TypingText steps={steps} typingSpeed={20}
          startTour={startTour}
          onTypingComplete={handleTypingComplete}
          showPWAInstallInstructions={showPWAInstallButton}
          />
        </div>
      )}
    </Fragment>    
  )
}

export default memo(Tour)
