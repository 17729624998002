import {Fragment, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'

// ******     REDUX STORE      ******
import { resetPass } from '../../redux/theUser'

// ******     MUI      ******
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

// import { Copyright } from '../index'

// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'


const ResetPassword = (props) => {
  if(debug) console.log("ResetPassword PROPS: ", props)
  const {
    // & Redux state PROPS
    resetPass,
    // & passed in as PROPS

  } = props

  const [userInfo, setUserInfo] = useState({
    password: "",
    confirmPassword: ""
  })
  const [statusMessage, setStatusMessage] = useState("")

  const [searchParams] = useSearchParams()
  let navigate = useNavigate()

  useEffect(() => {
    if(debug) console.log(`******   ResetPassword: useEffect()   ******`)
    if(debug) console.log("useEffect... searchParams.get(token) => ")
    if(debug) console.log(searchParams.get('token'))
    /* //TODO: Check that token is not expired!
      TODO:   Load a different page if the token IS expired...
    */
  }, [])

  const handleChange = (event) => {
    // TODO: Add form error handling — confirm passwords match!
    // #region
    // if (event.target.id === 'password') {
    //   if (isNaN(event.target.value)) {
    //     setPasswordError(true)
    //   } else {
    //     setPasswordError(false)
    //   }
    // }
    // #endregion

    setUserInfo({...userInfo, [event.target.id]: event.target.value})
  }
  const handleSubmit = async (event) => {
    event.preventDefault()

    if (userInfo.password === userInfo.confirmPassword) {
      const userCreds = {
        password: userInfo.password,
        token: searchParams.get('token')
      }

      let res = await resetPass(userCreds)
      setStatusMessage(res)
    }
    else {
      setStatusMessage("Passwords do not match!")
    }
    
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        
        <Box component="form" noValidate onSubmit={handleSubmit} 
          sx={{ mt: 3, width: '100%' }}
          >
          <Grid container spacing={2}>
            {statusMessage !== 'Password reset!' ? (
              <Fragment>
                <Grid item xs={12} sx={{ pb:1 }}>
                  <TextField
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    value={userInfo.password}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ pb:1 }}>
                  <TextField
                    required
                    fullWidth
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirmPassword"
                    value={userInfo.confirmPassword}
                    onChange={handleChange}
                  />
                </Grid>

                <Typography component="div" variant="body2" align='center' sx={{ width: '100%', color: 'error.main' }}>
                  {statusMessage}
                </Typography>
              </Fragment>
            ) : (
              <Typography component="div" variant="body1" align='center' sx={{ width: '100%', color: 'success.main', pt:2 }}>
                {statusMessage}
              </Typography>
            )}
          </Grid>

          {statusMessage !== 'Password reset!' ? (
            <Fragment>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Back to Sign in
                  </Link>
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <Button
              onClick={() => {navigate(`/login`)}}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              >
              Back to Sign in
            </Button>
          )}
          
        </Box>
      </Box>
      {/* <Copyright sx={{ mt: 5 }} /> */}
    </Container>
  )
}

const mapDispatch = (dispatch) => {
  console.log('Mapping dispatch to props')
  return {
    resetPass: (userInfo) => dispatch(resetPass(userInfo)),
  }
}

export default connect(null, mapDispatch)(ResetPassword)