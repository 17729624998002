import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

const LandingPage = () => {
  const isMobile = window.innerWidth <= 600 // Check for mobile viewport width
  const videoRef = useRef(null)
  
  useEffect(() => {
    // *** When the component mounts, blur the video element
    if (videoRef.current) {
      videoRef.current.blur()
    }
  }, []);  
  
  useEffect(() => {
    // *** Shift focus away from the video element to the body on load
    document.body.tabIndex = -1 // Make the body focusable without adding a tab stop
    document.body.focus() // Focus the body
  }, [])
  

  // *** Inline styles
  const videoContainerStyle = {
    width: 'fit-content',
    // maxHeight: isMobile ? '80vh' : '70vh',
    // maxHeight: '70vh',
    margin: 'auto',
    marginBottom: '0',
    marginTop: isMobile ? '0px' : '10px',
    // position: 'relative', // Position relative for absolute positioning of the video
  }

  const videoStyle = {
    width: '100%', // Responsive width
    // maxHeight: '100%', // Ensures video is not taller than its container
    maxHeight: '70vh',
    borderRadius: '8px', // Rounded corners
    objectFit: 'contain', // Ensures the video maintains aspect ratio without being cut off    
    outline: 'none',  // Removes the focus outline
    boxShadow: 'none'  // Ensures no shadow is applied when focused    
  }

  return (
    <Container component="main"
      maxWidth='false'
      sx={{ 
        bgcolor: 'primary.main',
        color: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        paddingTop: isMobile ? '20px' : 0 // Slightly lower the video on mobile
      }}
      >
      <Box sx={{ marginTop: isMobile ? '10px' : 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>

        <Grid container spacing={2} 
          justifyContent={'center'}
          >

          <Grid item xs={12} sm={10}>
            <div style={videoContainerStyle}>
              <video
                preload="metadata"
                ref={videoRef}
                controls={false}
                autoPlay
                muted
                loop
                playsInline
                style={videoStyle}
              >
                <source src="https://aimme-cdn.s3.us-east-1.amazonaws.com/example_videos/AIMme%20Landing%20Video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} 
          maxWidth='sm'
          justifyContent={'center'}
          >

          <Grid item xs={12} sm={10}>
            <Button 
              color='secondary'
              size='large'
              fullWidth variant="contained"
              component={Link} to="/signup"
              sx={{ mt: 3, mb: 1 }}
              >
              Sign Up
            </Button>
          </Grid>

          <Grid item xs={12} sm={10}>
            <Button
            color="white"
            size='large'
            fullWidth variant='outlined'
            component={Link} to="/login"
            >
              Login
            </Button>
          </Grid>
        </Grid>

      </Box>
    </Container>
  )
}

export default LandingPage
