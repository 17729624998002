import { Link } from 'react-router-dom'
import SnackbarContent from '@mui/material/SnackbarContent'
import Icon from '@mui/material/Icon'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

const SubscribeBanner = ({ timeLeft }) => {
  if (!timeLeft) {
    return null
  }

  const timeMessage = timeLeft.minutes !== undefined
    ? `${timeLeft.minutes} minutes`
    : `${timeLeft.hours} hours`

  return (
    <SnackbarContent
      sx={{
        maxWidth: 600,
        width: '100%',
        alignSelf: 'center',
        paddingBottom: '10px',
        '& .MuiSnackbarContent-action': {
          // paddingBottom: '6px',
          // mr: 0
        },
      }}
      icon={
        <Icon fontSize="small">
          <InfoOutlinedIcon />
        </Icon>
      }
      message={
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            p: 0,
            border: 'none',
            color: 'inherit',
            '& .MuiAlert-icon': {
              color: 'secondary.light'
            },
            '& .MuuiAlert-message': {
              // color: 'text.secondary'
              // color: 'primary.contrastText'
              opacity: '0.9'
            },
          }}
          >
          {timeMessage} until you can save your meal. Subscribe & skip the wait!
        </Alert>
      }
      action={
        <Button 
          variant="contained"
          size='small'
          color='secondary'
          disableElevation
          component={Link}
          to="/products"
        >
          Start Free Trial
        </Button>
      }
    />
  )
}

export default SubscribeBanner
