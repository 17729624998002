import axios from 'axios'

const dev = process.env.NODE_ENV === 'development'

// *** ACTION TYPES
const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
const GOT_SUBSCRIPTIONS = 'GOT_SUBSCRIPTIONS'
import { LOGGED_OUT } from './theUser'

// *** ACTION CREATORS
const gotSubscriptions = (subscriptions) => ({
  type: GOT_SUBSCRIPTIONS,
  subscriptions
})
export const setLoadingStatus = (loading)  => ({
  type: SET_LOADING_STATUS,
  loading
})

// *** THUNK CREATORS
export const getSubscriptions = () => async dispatch => {
  if(dev) console.log("getSubscriptions THUNK => ")

  try {
    const { data } = await axios.get(`/api/subscriptions/`)
    if (data) {
      dispatch(gotSubscriptions(data))
    }
  }
  catch (error) {
    dispatch(gotSubscriptions([]))
    console.error('Whoops, trouble getting active subscriptions!', error)
  }
  finally {
    dispatch(setLoadingStatus(false))
  }
}

// *** INITIAL STATE
const initialState = {
  loading: true,
  error: null,
  all: []
}

// *** REDUCER
const subscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOT_SUBSCRIPTIONS:
      console.log("GOT_SUBSCRIPTIONS => ", action.subscriptions)
      return {
        ...state,
        all: action.subscriptions,
      }
    case SET_LOADING_STATUS:
      return {...state, loading: action.loading}
    case LOGGED_OUT:
      console.log("LOGGED_OUT (subscriptions) => ", action.reducers)
      if(action.reducers.includes('subscriptions')) {
        if(dev) console.log("subscriptions reducer is included (in action)... return initialState!")
        return {...initialState, loading: false}
      }
      return {...initialState, loading: false}
    default:
      return state
  }
}

export default subscriptionsReducer
