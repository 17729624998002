import Badge from '@mui/material/Badge'
import {PickersDay} from '@mui/x-date-pickers/PickersDay'

let debug = localStorage.getItem("debuggerMode") === 'true'

const HighlightDay = (props) => {
  // if(debug) console.log("HighlightDay PROPS: ", props)
  const {
    // & Redux state PROPS

    // & passed in as PROPS
    // mealDates, onSelectDate, onMonthChange,
    isMealDate,
    day,
    outsideCurrentMonth,
    ...other
  } = props
    // console.log("outsideCurrentMonth ==> ", outsideCurrentMonth)
    // console.log("day.toString() ==> ", day.toString())
    // console.log("day.day ==> ", day.day)

    // console.log("isMealDate ===> ", isMealDate)
    // console.log("isMealDate(day) ==> ", isMealDate(day))
    let isHighlighted = isMealDate(day)
    if(isHighlighted) {
      console.log("isHighlighted ==> ", isHighlighted)
      console.log("day.toString() ==> ", day.toString())
    }
  
    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        // badgeContent={isMealDate(day) ? '😄' : undefined}
        // badgeContent={isHighlighted ? '🍽️' : undefined}
        badgeContent={isHighlighted ? isHighlighted : undefined}
        sx={{
          '& .MuiBadge-badge': {backgroundColor: 'primary.light'}
        }}
      >
        <PickersDay 
          day={day} 
          outsideCurrentMonth={outsideCurrentMonth} 
          sx={
            isMealDate(day) ? {
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText'
              // backgroundColor: 'primary.light',
            }: null
          } 
          {...other} 
          />
      </Badge>
    )
}

export default HighlightDay

