import { createStore, applyMiddleware } from 'redux'

import appReducer from './redux'

import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'  //^ <== must be last middleware

// *** Additional dev libraries to consider
// import { composeWithDevTools } from 'redux-devtools-extension'


// *** convert object to string and store in localStorage
function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state)
    localStorage.setItem("persistantState", serialisedState)
  }
  catch (error) {
    console.warn(error)
  }
}
// *** load string from localStarage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("persistantState")
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  }
  catch (error) {
    console.warn(error)
    return undefined
  }
}


const store = createStore(
    appReducer,
    loadFromLocalStorage(),
    applyMiddleware(
        thunkMiddleware,
        createLogger({ collapsed: true })
    )
)


// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => saveToLocalStorage(store.getState()))

export default store
