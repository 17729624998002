import { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { DateTime } from 'luxon'

import { logout } from '../../redux/theUser'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
// import MenuIcon from '@mui/icons-material/Menu'
import Divider from '@mui/material/Divider'
// import AccountCircle from '@mui/icons-material/AccountCircle'
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
// ? Home / Dash
// import DashboardIcon from '@mui/icons-material/Dashboard'
import HomeIcon from '@mui/icons-material/Home'
// ? Subscription
// import CardMembershipIcon from '@mui/icons-material/CardMembership'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
// import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
// import SellIcon from '@mui/icons-material/Sell'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
// ? Products (if not subscribed)
// import StoreIcon from '@mui/icons-material/Store'



import { AimeeLogo, ColoredLetterAvatar } from '../index'

let debug = localStorage.getItem("debuggerMode") === 'true'


const NavBar = (props) => {
  if(debug) console.log("NavBar PROPS: ", props)
  const {
    // & Redux PROPS
    isLoggedIn,
    userFullName,
    logout,
    isSubscribed,
    // & passed in as PROPS

  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="static"
        // elevation={0}
        >

        <Toolbar>
          <Box
            sx={{ 
              flexGrow: 1,
              flexDirection: 'row',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'left'
            }}
            >
            <Box component={Link} to="/chat" data-tour="logo">
              <AimeeLogo height={'48px'} width={'48px'} />
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'column', 
              justifyContent: 'center'
              }}>
              <Typography variant="h6" component={Link} to="/chat" color="white.main">
                AIMme
              </Typography>
            </Box>
          </Box>

          {!isLoggedIn ? (
            <Fragment>
              <Button color="inherit" component={Link} to="/signup">Signup</Button>

              <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: "rgb(248 248 248 / 37%)", borderWidth: "1px"}} />

              <Button color="inherit" component={Link} to="/login">Login</Button>
            </Fragment>
            ) : (
            <div>
              <IconButton
                // size="large"
                // size="medium" // ^ default
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {/* <AccountCircle /> */}
                <ColoredLetterAvatar
                  fullName={(userFullName) ? userFullName : 'Ooooh Ooough'}
                  // size={{width: 56, height: 56}}
                  // size={{width: 48, height: 48}} // ^ size of AimeeLogo
                  // size={{width: 40, height: 40}} // ^ default size of MuiAvatar
                  // size={{width: (40 * 0.9), height: (40 * 0.9)}} // ^ 90% MuiAvatar DEFAULT size
                  sx={{ 
                    // mr: 1
                    width: 'calc(40px* 0.9)',
                    height: 'calc(40px* 0.9)',
                    fontSize: '0.9rem', // ^ default of MuiAvatar-root {font-size: 1.0714285714285714rem;}
                  }}
                  />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem 
                  onClick={handleClose}
                  // component={Link} to="/profile"
                  >
                  <Avatar /> Profile
                </MenuItem> */}
                <MenuItem 
                  onClick={handleClose}
                  // component={Link} to="/profile"
                  >
                  <ColoredLetterAvatar
                    fullName={(userFullName) ? userFullName : 'Ooooh Ooough'}
                    // size={{width: 40, height: 40}} // ^ default size of MuiAvatar
                    // size={{width: (40 * 0.9), height: (40 * 0.9)}} // ^ 90% MuiAvatar DEFAULT size
                    sx={{
                      mr: 1,
                      width: 'calc(40px* 0.9)',
                      height: 'calc(40px* 0.9)',
                      fontSize: '0.9rem', // ^ default of MuiAvatar-root {font-size: 1.0714285714285714rem;}
                    }}
                    /> {userFullName ? userFullName : 'Hold, please.'}
                </MenuItem>
                {/* <MenuItem 
                  onClick={handleClose}
                  // component={Link} to="/account"
                  >
                  <Avatar /> My account
                </MenuItem> */}

                <Divider light />

                <MenuItem onClick={handleClose} component={Link} to="/home">
                  <ListItemIcon><HomeIcon fontSize='small' /></ListItemIcon>
                  <ListItemText>Dashboard</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={isSubscribed ? "/subscription" : "/products"}>
                  <ListItemIcon>{isSubscribed ? <WorkspacePremiumIcon fontSize='small' /> : <LoyaltyIcon fontSize='small' />}</ListItemIcon>
                  <ListItemText>{isSubscribed ? 'Subscription' : 'Products'}</ListItemText>
                </MenuItem>

                <Divider light />

                <MenuItem onClick={handleClose} component={Link} to="/chat">
                  <ListItemIcon>
                    <ChatBubbleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Chat</ListItemText>
                </MenuItem>

                <Divider light />

                <MenuItem 
                  onClick={() => {
                    handleClose()
                    logout()
                  }}
                  >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}

        </Toolbar>
      </AppBar>
    </Box>
  )
}

const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  let currentUserSub = state.theUser.userSubs?.current

  return {
    isLoggedIn: !!state.theUser.id,
    userFullName: state.theUser.name ? state.theUser.name : null,
    // isSubscribed: !!state.theUser.subscriptions?.current?.isActive
    isSubscribed: currentUserSub?.endDate ? (DateTime.fromISO(currentUserSub.endDate) > DateTime.now()) : false
  }
}
const mapDispatch = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapState, mapDispatch)(NavBar)
