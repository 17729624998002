import { useState, useEffect, useRef, Fragment, useMemo, useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { gotMeal, extractFoodText, extractFoodImage, setLoadingStatus } from '../redux/meals'
import { uploadMedia, uploadedMedia } from '../redux/theUser'

import { Box, TextField, IconButton, CircularProgress, Typography, Fade } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import InputAdornment from '@mui/material/InputAdornment'
import SendIcon from '@mui/icons-material/Send'
import CloseIcon from '@mui/icons-material/Close'
// import AttachmentIcon from '@mui/icons-material/Attachment'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import { ImageList, TypingText } from './index'

import { processingMessages } from '../utils/nutrition'

// Tour values
// Hardcoded meal objects
import { demomealObjects, firstTourSteps, endingChatbotTourSteps, selectedMealTourSteps } from './tour/tourValues'

let debug = localStorage.getItem("debuggerMode") === 'true'


const Chatbot = (props) => {
  if(debug) console.log("Chatbot PROPS: ", props)
  const {
    // & Redux PROPS
    isLoggedIn, isMealLoading, isImageUploading, isError, isTheUserLoading, error,
    selectedMeal,
    extractFoodText, extractFoodImage,
    uploadMedia,
    uploadedMealImage,
    // & passed in as PROPS

  } = props


  //////// ?             HANDLE ANIMATION during checkTaskStatus                 ////////
  // #region
  const [processingMessage, setProcessingMessage] = useState('')
  let timeoutId // Needed to prevent state updates once component has unmounted
 
  useEffect(() => {
    if(debug) console.log(`useEffect()..........   
      isMealLoading: ${isMealLoading} 
      | isTheUserLoading: ${isTheUserLoading}
      | isImageUploading: ${isImageUploading} 
      | isError: ${isError} 
      | uploadedMealImage: ${uploadedMealImage}
      | !!selectedMeal.foods = ${!!selectedMeal.foods}`)

    
    let intervalId

    if (isMealLoading) {
      const updateMessage = () => {
        // Hide message to start fade out
        setShowProcessingMessage(false)
        
        timeoutId = setTimeout(() => {
          // Change message and start fade in after fade out
          setProcessingMessage(processingMessages[Math.floor(Math.random() * processingMessages.length)])
          setShowProcessingMessage(true)
        }, 500)
      }

      updateMessage() // Initial message update
      intervalId = setInterval(updateMessage, 4000) // Total 4000ms for fade out + fade in
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
      if (timeoutId) clearTimeout(timeoutId) // Clear the timeout on unmount
    }
  }, [isMealLoading])
  

  // ******     HANDLE MESSAGES SCROLLING + Text Input LOGIC     ******
  // #region

  const [chatMessages, setChatMessages] = useState([])
  const [inputAreaHeight, setInputAreaHeight] = useState(0)
  const [showProcessingMessage, setShowProcessingMessage] = useState(false)

  const inputAreaRef = useRef(null)
  const messagesEndRef = useRef(null)

  useEffect(() => {
    const updateInputAreaHeight = () => {
      if(inputAreaRef.current) {
        setInputAreaHeight(inputAreaRef.current.clientHeight)
      }
    }
  
    // ? Initial calculation
    updateInputAreaHeight()
  
    // ? Add event listeners for window resize and input changes
    window.addEventListener('resize', updateInputAreaHeight)
    const observer = new MutationObserver(updateInputAreaHeight)
    observer.observe(inputAreaRef.current, { attributes: true, childList: true, subtree: true })
  
    // ? Cleanup
    return () => {
      window.removeEventListener('resize', updateInputAreaHeight)
      observer.disconnect()
    }
  }, [])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatMessages]) // Assuming chatMessages is your array of chat messages

  // #endregion

  // TODO: progress bar tied to Redux store
  const [chatText, setChatText] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [chatImage, setChatImage] = useState({URL: null, title: null})

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isMealsLoading = useSelector(state => state.meals.loading)
  const mealsForSubscribe = useSelector(state => state.meals.count.forSubscribe)
  
  useEffect(() => {
    if(debug) console.log(`useEffect()..........   
      selectedMeal.taskId: ${selectedMeal.taskId} 
      | selectedMeal.id: ${selectedMeal.id} 
      | selectedMeal.foods.length: ${selectedMeal.foods && selectedMeal.foods.length ? selectedMeal.foods.length : null} 
      | !!selectedMeal.foods = ${!!selectedMeal.foods}`)

    const tourComplete = localStorage.getItem('tourV2Complete') === 'true'
    
    // ? Logic if the meal is a logged within a tour
    if(!tourComplete && !isMealsLoading && (!mealsForSubscribe || mealsForSubscribe.length === 0)){
      if(selectedMeal && selectedMeal.taskId && selectedMeal.foods && selectedMeal.foods.length) {
        if(debug) console.log("demo meal was selected")
        navigate('/meal/demo')
      }
    }
    // ? Logic for ALL other meal logging...
    else {      
      if(selectedMeal && selectedMeal.taskId && selectedMeal.foods && selectedMeal.foods.length) {
        if(debug) console.log("The TEXT meal is fully loaded... (has taskId && foods)... time to navigate to /meal page!")
        navigate('/meal')
      }
      if(selectedMeal && selectedMeal.taskId && selectedMeal.entryType && selectedMeal.entryType === 'image' && selectedMeal.foodComponents) {
        if(debug) console.log("The IMAGE meal is fully loaded... (has taskId && foods)... time to navigate to /meal page!")
        if(chatImage.URL) dispatch(uploadedMedia({}))
        navigate('/meal')
      }
    }
  }, [selectedMeal])

  useEffect(() => {
    if(debug) console.log("useEffect().... uploadedMealImage ==> ", uploadedMealImage)
    if(debug) console.log("useEffect().... !!uploadedMealImage.Location ==> ", !!uploadedMealImage.Location)
    if(!!uploadedMealImage.Location) {
      setChatImage({URL: uploadedMealImage.Location, title: uploadedMealImage.key})
    }
  }, [uploadedMealImage])

  const handleFileChange = (event) => {
    
    // Close any tour bubble that's up while file change is going on
    handleCloseTour()
    
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      setSelectedFile(file)
      uploadMedia(file)
      if(debug) console.log(file)
        
      // Trigger the next set of tour steps if the user selects a file
      if(debug) console.log("isTourOngoing => ", isTourOngoing)
      if(isTourOngoing){
        updateTourStepsAfterImageSelection()
        setShowTour(true)
        // Reset highlights after handling file change
        setHighlightPhotoIcon(false)
        setHighlightSendButton(true)        
      }
    }  
  }

  const handleAttachmentClick = () => {
    if(debug) console.log('clicked attachment...')
    document.getElementById('fileInput').click()
  }

  const handleChange = (event) => {
    // setUserInfo({...chatText, [event.target.id]: event.target.value})
    setChatText(event.target.value)
    // if(debug) console.log("handleChange.... chatText => ", chatText)
  }

  const handleKeyDown = (event) => {
    // Check if Enter key is pressed along with Cmd (on Mac) or Ctrl (on Windows/Linux)
    if ((event.key === 'Enter') && (event.metaKey || event.ctrlKey)) {
      event.preventDefault()  // Prevents the default action of creating a new line
      handleSubmit(event)   // Calls the handleSubmit function to process the meal
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    
    handleCloseTour()

    if(debug) console.log("handleSubmit.... chatText => ", chatText)
    if(!!uploadedMealImage.Location) {
      if(debug) console.log("Image attached... send foodImage => ", uploadedMealImage.Location)
      // extractFoodImage(uploadedMealImage.Location)
      extractFoodImage(chatImage.URL, window)
      setChatImage({URL: null, title: null})
    }
    
    // Tour "demo meal" case: The chatbox image changed but there's not actually an uploaded meal
    else if(chatImage.URL && !uploadedMealImage.Location){
      if(debug) console.log('***CHAT HAS AN IMAGE BUT NO IMAGE UPLOADED***')
      if(debug) console.log('chatImage => ', chatImage)
        
      setShowTour(false)
      dispatch(setLoadingStatus(true))
      
      setHighlightPhotoIcon(false)
      setHighlightSendButton(false)      
      
      // HARDCODED! Meal data that correlates to the selected image
      let demoMeal = demomealObjects[chatImage.title]
      if(debug) console.log('demoMeal => ', demoMeal)
    
      // Mimic a slight delay so the user knows images take a bit to process
      timeoutId = setTimeout(() => {
        dispatch(setLoadingStatus(false))
        dispatch(gotMeal(demoMeal))
      }, 5000)
    }
    
    else{
      if(chatText) extractFoodText(chatText, window)
    }
  }

  // TODO: Move this out into its own tour file  
  //////// ?             Handle Tour Logic                 ////////
  // #region
  
  const [isTourOngoing, setIsTourOngoing] = useState(false)
  const [showTour, setShowTour] = useState(false)
  const [showTourImages, setShowTourImages] = useState(true)
  const [tourSteps, setTourSteps] = useState([])
  const [highlightPhotoIcon, setHighlightPhotoIcon] = useState(false)
  const [highlightSendButton, setHighlightSendButton] = useState(false)
  const [startTourFlag, setStartTourFlag] = useState(true)
  const [isTourComplete, setIsTourComplete] = useState(false)
  const [showPWAInstallButton, setShowPWAInstallButton] = useState(false)
    
  // ? Check local storage to see if tour was previously skipped
  useEffect(() => {
    if (!startTourFlag) return
    const tourComplete = localStorage.getItem('tourV2Complete') === 'true'
    const params = new URLSearchParams(location.search)
    const finishTour = params.get('finish_tour')
    
    // ? Check if tour was previously skipped or if there are items in forSubscribe
    if (finishTour || !tourComplete && !isMealsLoading && (!mealsForSubscribe || mealsForSubscribe.length === 0)) {
      if(!finishTour){
        setTourSteps(firstTourSteps)
        setIsTourOngoing(true)
      }
      else{
        setTourSteps(endingChatbotTourSteps)
        setShowTourImages(false)
        setIsTourComplete(true)
        setShowPWAInstallButton(true)
        localStorage.setItem('tourV2Complete', 'true')
      }
      startTour()

      try {
        window.mixpanel.track("Tour Started")
      }
      catch (error) {
        if(debug) console.log("mixpanel failed ==> ", error)
      }
  
    } 
  }, [mealsForSubscribe, startTourFlag])
  
  // TODO: Refactor with Dashboard
  // useEffect to check for standalone mode, which will close out tour and not annoy user
  useEffect(() => {
    const checkStandaloneMode = () => {
      const isStandalone = document.querySelector('body').classList.contains('progressier-standalone')
      if (isStandalone) {
        localStorage.setItem('tourV2Complete', 'true')
        handleCloseTour()
      }
    }

    // Check initially
    checkStandaloneMode()

    // Add a delay to check again, as there might be a slight delay before the class is added
    const timeoutId = setTimeout(checkStandaloneMode, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])  
    
  const startTour = () => {
    setShowTour(true)
    setStartTourFlag(false)
  }

  const handleCloseTour = () => {
    setShowTour(false)
    setHighlightPhotoIcon(false)
    setHighlightSendButton(false)
  }
  
  // Define handleTypingComplete as a stable function
  const handleTypingComplete = useCallback(() => {
    setHighlightPhotoIcon(true)
  }, [])  
  
  const updateTourStepsAfterImageSelection = () => {
    setTourSteps(selectedMealTourSteps)
    setShowTourImages(false) // Disable showing images after the first set
    setHighlightPhotoIcon(false)
    setHighlightSendButton(true)
  }
  // #endregion
    
  return (    
    // ? Box HEIGHT === vh - navbar height
    <Box 
      sx={{ 
        display: 'flex', flexDirection: 'column', 
        justifyContent: 'flex-end', 
        height: 'calc(100vh - 66px)', /* old browsers */  
        height: 'calc(100dvh - 66px)',  /* new browsers */
      }}
      >
      
      {/* // TODO: Refactor out of this fiel and use abstracted tour version */}
      {showTour && !isMealLoading && (
      <div className="speech-bubble">
        <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleCloseTour}
            size="small"
          >
          <CloseIcon />
        </IconButton>
        <TypingText steps={tourSteps} typingSpeed={20} setChatImage={(image) => {
            setChatImage(image)
            updateTourStepsAfterImageSelection()
          }} showTourImages={showTourImages}
          // updateTourUploadedMealImage={updateTourUploadedMealImage} 
          startTour={startTour}
          onTypingComplete={handleTypingComplete}
          showPWAInstallInstructions={showPWAInstallButton}
          />
      </div>
      )}

      {/* Messages container */}
      <Box 
        sx={{ 
          flexGrow: 1, overflowY: 'auto', padding: 2, 
          // position: 'relative',
          // bottom: `${inputAreaHeight}px`,
          marginBottom: `${inputAreaHeight}px`,
          // maxHeight: `calc(100dvh - 66px - ${inputAreaHeight}px)`,
          // maxHeight: `calc(100% - ${inputAreaHeight}px)`,
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
        }}
        >

        {/* Messages will be displayed here */}

        {/* // ******   PROGRESS INDICATOR    ****** */}
        {isMealLoading && !isImageUploading && (
          <Box
            paddingY={3}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}
            >
            <CircularProgress />
            <Fade in={showProcessingMessage} timeout={500}>
              <Typography sx={{ mt: 2 }}>{processingMessage}</Typography>
            </Fade>
          </Box>
        )}

        {/* // ******   ERROR HANDLING    ****** */}
        {!isMealLoading && error && (
          <Box
            paddingY={3}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}
            >
            <Typography variant='h3' sx={{ mt: 2 }}>
              Uh oh!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Something happened while processing your meal.
            </Typography>
            <Typography sx={{ mt: 2, mb: 3 }}>
              Please adjust and try again.
            </Typography>

            {/* //! TODO: UPDATE with statusCode for text meals!!  */}
            {/* // ******   ERRORS: Text Meals    ****** */}
            {error?.enteredText && error?.statusText && (
              <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>Error Status Code</AlertTitle>
                {`${error.status} ${error.statusText} | Unable to parse entry text: '${error.enteredText}'`}
              </Alert>
            )}

            {/* // ******   ERRORS: Image Meals    ****** */}
            {error?.enteredImage && error?.statusCode && (
              <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>Error Status Code</AlertTitle>
                {error.statusCode}
              </Alert>
            )}
          </Box>
        )}

        <div ref={messagesEndRef} />
      </Box>

      {/* // ******    INPUT AREA     ****** */}
      <Box 
        ref={inputAreaRef}
        component="form" onSubmit={handleSubmit} 
        sx={{ 
          display: 'flex', maxHeight: '129px', alignItems: 'center', padding: 1, borderTop: '1px solid #ccc', 
          position: 'fixed', bottom: 0, left: 0, right: 0, 
          backgroundColor: 'background.paper' 
        }}
        >
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="image/*"
        />
        <IconButton aria-label="attach" onClick={handleAttachmentClick} data-tour="photo-upload"
          sx={{ 
            color: highlightPhotoIcon && !highlightSendButton && !isTourComplete ? 'secondary.main' : 'default'
          }}        
        >
          {/* <AttachmentIcon /> */}
          <AddAPhotoIcon />
        </IconButton>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          placeholder={!chatImage.URL ? "e.g. I ate 3 oz chicken and 5 strawberries..." : `
          Send image to Aimme...
          `}
          // handle input change
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          // disabled={isMealLoading || !!uploadedMealImage.Location}
          disabled={isMealLoading || !!chatImage.URL}
          InputProps={{
            // startAdornment: !!uploadedMealImage.Location ? (
            startAdornment: !!chatImage.URL ? (
              <InputAdornment position="start">
                <ImageList imgData={[chatImage]} />
              </InputAdornment>
            ) : (
              isImageUploading ? (
                <InputAdornment position="start">
                  <CircularProgress />
                </InputAdornment>
              ) : null
            ),
          }}
        />
        <IconButton
          type="submit"
          // onClick={handleSubmit}
          aria-label="send"
          disabled={isMealLoading || isImageUploading}
          data-tour="submit"
          sx={{ 
            color: highlightSendButton && !isTourComplete ? 'secondary.main' : 'default'
          }}
          >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    isLoggedIn: !!state.theUser.id,
    isTheUserLoading: state.theUser.loading,
    isMealLoading: state.meals.loading,
    isImageUploading: !!state.theUser.mediaInfo?.loading,
    isError: !!state.meals.error,
    error: state.meals.error,
    selectedMeal: state.meals.selected,
    uploadedMealImage: state.theUser.mediaInfo
  }
}
const mapDispatch = (dispatch) => {
  return {
    extractFoodText: (foodText, window) => dispatch(extractFoodText(foodText, window)),
    extractFoodImage: (foodImage, window) => dispatch(extractFoodImage(foodImage, window)),
    uploadMedia: (theFile) => dispatch(uploadMedia(theFile)),
    gotMeal: (meal) => dispatch(gotMeal(meal)),
    setLoadingStatus: (status) => dispatch(setLoadingStatus(status)),
  }
}

export default connect(mapState, mapDispatch)(Chatbot)
