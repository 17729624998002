import { Fragment, useState } from 'react'
import { connect } from 'react-redux'

import Chip from '@mui/material/Chip'


let debug = localStorage.getItem("debuggerMode") === 'true'


const MealFoodNutrition = (props) => {
  if(debug) console.log("MealFoodNutrition PROPS: ", props)
  const {
    // & Redux PROPS
    isMealLoading,
    // & passed in as PROPS
    isTextMeal, isV2ImageMeal,
    isFood,
    item, index, // foodItems.map(item, index) => 
    calories, protein, carbs, fat, fiber,
  } = props

  if(debug) console.log("isFood ==> ", isFood)
  if(debug) console.log("isTextMeal ==> ", isTextMeal)

  if(debug) console.log("item.consumed_calories ==> ", item.consumed_calories ? item.consumed_calories: null)
  if(debug) console.log("item.calories ==> ", item.calories ? item.calories: null)

  return (
    <Fragment>
      <Chip 
        label={`${calories} cal`}
        color="primary" 
        size="small" 
        sx={{margin: "0px 5px", fontSize: "85%"}} 
        />
      <Chip 
        label={`P ${isFood ? (isTextMeal || isV2ImageMeal ? item.consumed_protein_gs.toFixed(0) : item.protein) : Number(item.protein).toFixed(0)}g`}
        size="small"
        sx={{margin: "0px 2px", fontSize: "85%"}} 
        />
      <Chip 
        label={`C ${isFood ? (isTextMeal || isV2ImageMeal ? item.consumed_carb_gs.toFixed(0) : item.carbohydrates) : Number(item.carbs).toFixed(0)}g`} 
        size="small" 
        sx={{margin: "0px 2px", fontSize: "85%"}} 
        />
      <Chip 
        label={`F ${isFood ? (isTextMeal || isV2ImageMeal ? item.consumed_fat_gs.toFixed(0) : item.fat) : Number(item.fat).toFixed(0)}g`} 
        size="small"
         sx={{margin: "0px 2px", fontSize: "85%"}} />
      {debug ? (
        <Chip 
          label={`Fiber ${isFood ? (isTextMeal || isV2ImageMeal ? item.consumed_fiber_gs.toFixed(0) : item.fiber) : Number(item.fiber).toFixed(0)}g`} 
          size="small" sx={{margin: "0px 2px", fontSize: "85%"}} />
        ) : null}
    </Fragment>
  )
}


const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  return {
    isMealLoading: state.meals.loading,
  }
}
const mapDispatch = (dispatch) => {
  return {
    
  }
}

export default connect(mapState, mapDispatch)(MealFoodNutrition)
