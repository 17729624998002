/* // ? STILL in transition from Dick's use of:
  ^ * getSubscription
  ^ * userSubscription = useSelector(state => state.theUser.subscriptions.current)
      ~ BOTH above, poorly named ==> subscriptions mean something else
        ~ + API route does NOT return complete DB object for user's subscription...
  ^ * checkSubscriptionGating()
      ~ defined within component and using variables in React component scope
  ? OVER to use of:
  ^ * getUserSub
  ^ * latestUserSub = useSelector(state => state.theUser.userSubs.current)
      ~ BOTH above, accurately named ==> diff b/w userSub & subscription (DB models)
        ~ + API route returns complete userSub + {includes: Subscription}... 
            !...(included model -> used in <Product/> && <SubscriptionManager/>)
  ^ * checkUserSubStatus(latestUserSub)
      ~ GOAL: defined outside component, all params passed to func
*/
import { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import { Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'

import { 
  Checkout, Product, SubscriptionManager, ThankYou, FreeTrialVideo
 } from '../index'

import { 
  getUserSub, gotUserSub,
  logout
} from '../../redux/theUser'

let debug = localStorage.getItem("debuggerMode") === 'true'
// const userVariationList = ['trial_start_gate', 'control']

const SubscribeRoutes = (props) => {
  if(debug) console.log("SubscribeRoutes PROPS: ", props)
  
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const location = useLocation()

   // *** STATES
  const isLoggedIn = useSelector(state => !!state.theUser.id)
  const userId = useSelector(state => state.theUser.id)
  // const mealsByType = useSelector(state => state.meals.count.forSubscribe)
  const latestUserSub = useSelector(state => state.theUser.userSubs.current)
  // const firstUtmContent = useSelector(state => state.theUser.firstUtmContent)

  // const [userVariation, setUserVariation] = useState(null)


  useEffect(() => {
    if(isLoggedIn) {
      if(debug) console.log("SubscribeRoutes useEffect()..... getUserSub() dispatched...")
      dispatch(getUserSub())
    }
  }, [isLoggedIn])

  // * Check userSub Status: If inactive, check if user needs to refresh their UserSub (via Stripe) and/or be forwarded to checkout
  const checkUserSubStatus = async (userSub) => {
    if(debug) console.log("checkUserSubStatus( userSub )...  userSub.endDate ==> ", userSub.endDate)
    let userSubEndDateTime = DateTime.fromISO(userSub.endDate)
    let isActive = userSubEndDateTime > DateTime.now()
    if(debug) console.log("Checking if latestUserSub isActive...  ==> ", isActive)

    if(userSubEndDateTime.invalid) {
      if(debug) console.log(`Checking for issue with DateTime... userSubEndDateTime.invalid ==> `, userSubEndDateTime.invalid)
      return null
    }
    // * If user has a userSub but it's not active, check for renewal (i.e. auto-renewal via Stripe).
    if(!isActive) {
      if(debug) console.log(`Latest userSub in DB is NOT active!! Checking Stripe for renewal by subscriptionId: ${userSub.stripeUserSubscriptionId}...`)

      try {
        const { status, data } = await axios.post('/api/services/stripe/check-usersub-for-renewal', {
          stripeUserSubscriptionId: userSub.stripeUserSubscriptionId,
          endDate: userSub.endDate
        })

        if(debug) console.log(`Checked Stripe for userSub renewal successfully!
          status ==> ${status}
          data ==> `, data)

        if(status !== 204 && data && data !== "") {
          if(debug) console.log('Found new user subscription via Stripe!')
          if(debug) console.log(`userSub in DB -> up-to-date with Stripe!! 
          Dispatching gotUserSub() with data...`)

          dispatch(gotUserSub(data))
        }
        else {
          if(debug) console.log('NO new user subscription found via Stripe.')
          dispatch(gotUserSub({}))

          if(debug) console.log(`userSub is NOT ACTIVE!! Returning false...`)
          return false
        }
      }
      catch (error) {
        if(debug) console.error('Error checking UserSub for renewal via Stripe: ', error)
        console.log("error ==> ", error)
      
        console.log("error.name ==> ", error.name)
        console.log("error.message ==> ", error.message)
        if(debug) console.log("error.response?.data?.error?.name ==> ",  error.response?.data?.error?.name)
        // if(error.name === 'MissingUserError') {
        if(error.response?.data?.error?.name === 'MissingUserError') {
          console.log("User not logged in! Wipe local storage with theUser.logout()...")
          dispatch(logout())
        }
      }
    }
    else {
      if(debug) console.log(`userSub in DB is ACTIVE!! Returning true...`)
      return true
    }
  }

  // * Updating userSub: Check if userSub is active, if not, sent through gate based on userVariant...
  useEffect(async () => {
    if(debug) console.log('Subscription GATING [updated] useEffect()...')
    if(debug) console.log("Checking state.theUser.userSubs.current... latestUserSub ==> ", latestUserSub)

    if(latestUserSub) {
      if(debug) console.log("LOADED latestUserSub...")

      // ? 1st logic check -> wasSubscriber (userSub w/ endDate)
      if(!!latestUserSub.endDate) {
        let activeStatus = await checkUserSubStatus(latestUserSub)
        if(debug) console.log("activeStatus ==> ", activeStatus)
      }
    }
  }, [isLoggedIn, latestUserSub, location.pathname])

  
  return (    
    <Fragment>
      {/* //******    Logged In   ****** */}
      {isLoggedIn && (
        <Fragment>
          <Route path="/subscription" element={<SubscriptionManager />} />
          <Route path="/products" element={<Product />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/freetrial-video" element={<FreeTrialVideo />} />
        </Fragment>
      )}

      {/* //******    NOT Logged In   ****** */}
      {!isLoggedIn && (
          <Fragment>
            <Route path="/subscription" element={<Navigate to="/login" replace />} />
            <Route path="/products" element={<Navigate to="/login" replace />} />
            <Route path="/checkout" element={<Navigate to="/login" replace />} />
            <Route path="/thankyou" element={<Navigate to="/login" replace />} />
            <Route path="/freetrial-video" element={<Navigate to="/login" replace />} />
          </Fragment>
      )}        
    </Fragment>
  )
}

export default SubscribeRoutes
