import { useState, useEffect, useRef, Fragment, memo } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TypingText from './TypingText'
import { userMealAnalysis, demoMealAnalysis } from './tourValues'

// ******     DEBUGGER      ******
let debug = localStorage.getItem("debuggerMode") === 'true'

const MealFoodGridTour = () => {
  const [steps, setSteps] = useState([])
  const [showTour, setShowTour] = useState(false)
  const selectedMeal = useSelector(state => state.meals.selected)
  
  const startTour = () => {
    setShowTour(true)
  }

  const handleCloseTour = () => {
    setShowTour(false)
  }
  
  useEffect(() => {
    if(debug) console.log("***MealFoodGridTour useEffect called***")    
    if(debug) console.log("selectedMeal => ", selectedMeal)
    
    // Show different message depending on whether or not a real meal was used
    if(selectedMeal.taskId === 'demo'){
      setSteps(demoMealAnalysis)
    }
    else{
      setSteps(userMealAnalysis)
    }
      
    startTour()
  }, [])  // Empty dependency array ensures this runs only once

  const handleTypingComplete = () => {
    // Handle what happens when typing is complete
    if(debug) console.log('Typing complete')
  }
    
  return (
    <Fragment>
      {showTour && (
        <div className="speech-bubble">
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleCloseTour}
            size="small"
          >
            <CloseIcon />
          </IconButton>          
          <TypingText steps={steps} typingSpeed={20}
          startTour={startTour}
          onTypingComplete={handleTypingComplete} // Pass the function here
          />
        </div>
      )}
    </Fragment>    
  )
}

export default MealFoodGridTour
