import { Fragment } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
// import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'


let debug = localStorage.getItem("debuggerMode") === 'true'


export default function ConfirmeDialog(props) {
  if(debug) console.log("ConfirmDialog PROPS: ", props)
  const {
    // & Redux PROPS

    // & passed in as PROPS
    openDialog, setOpenDialog,
    dialogTitle, contentText,
    yesText, yesFunc,
    noText, noFunc
  } = props

  const theme = useTheme()
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    // setOpen(false)
    setOpenDialog(false)
  }

  return (
    <Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={noFunc}>
            {noText}
          </Button>
          <Button onClick={yesFunc} autoFocus>
            {yesText}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
