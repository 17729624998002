import { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import { DateTime } from 'luxon'

import { 
  // useDispatch,
  useSelector 
} from 'react-redux'
import { useNavigate } from 'react-router-dom'

// import { getSubscriptions } from '../redux/subscriptions'

import axios from 'axios'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'

let debug = localStorage.getItem("debuggerMode") === 'true'


const SubscriptionManager = (props) => {
  if(debug) console.log("SubscriptionManager PROPS: ", props)
  const {
    // & Redux PROPS
    // theUser, userId,
    isUserSubActive
    // & passed in as PROPS

  } = props

  // const dispatch = useDispatch()
  const navigate = useNavigate()

  const latestUserSub = useSelector(state => state.theUser.userSubs.current)

  const [stripeSubscription, setStripeSubscription] = useState(null)
  const [isCancelling, setIsCancelling] = useState(false)

  
  useEffect(() => {
    if (latestUserSub && isUserSubActive) {
      // * Fetch subscription details from Stripe
      if(debug) console.log("User has a subscription!")
      if(debug)console.log("latestUserSub: ", latestUserSub)

      axios.get(`/api/services/stripe/get-subscription/${latestUserSub.stripeUserSubscriptionId}`)
        .then(response => {
          if(debug) console.log("/get-subscription/  response ==> ", response)
          setStripeSubscription(response.data)
          if(debug) console.log("latestUserSub...")
          if(debug) console.log(latestUserSub)
          if(debug) console.log("stripeSubscription...  response.data ==> ", response.data)
          if(debug) console.log(stripeSubscription)
        })
        .catch(error => {
          console.error('Error fetching subscription details from Stripe:', error)
          // console.log("error.name ==> ", error.name)
          // console.log("error.message ==> ", error.message)
          // console.log("error.response ==> ", error.response)
          // console.log("error.response.data ==> ", error.response.data)
          if(debug) console.log("error.response?.data?.error?.name ==> ",  error.response?.data?.error?.name)
          if(debug) console.log("error.response?.data?.error?.message ==> ",  error.response?.data?.error?.message)
        })
    }
    else {
      // * Redirect to products page if no active subscription
      navigate('/products')
    }
  }, [latestUserSub, isUserSubActive])

  // Make timestamp human readable
  const formatDate = (timestamp) => {
    return DateTime.fromSeconds(timestamp).toLocaleString(DateTime.DATE_FULL)
  }

  // User clicks cancel subscription button
  const handleCancelSubscription = () => {
    setIsCancelling(true) // Start cancelling process
    axios.post('/api/services/stripe/cancel-subscription', { subscriptionId: stripeSubscription.id })
      .then(() => {
        setIsCancelling(false)
        window.location.reload() // Refresh the page
      })
      .catch(error => {
        console.error('Error cancelling subscription:', error)
        setIsCancelling(false) // End cancelling process
      })
  }

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>                
        {stripeSubscription ? (
          <Grid item xs={12} justifyContent="center">
            <Typography component="h2" variant="h4">
              Manage Your AIMme Subscription
            </Typography>

            <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'center' }}>
              <Grid item xs={12} sx={{ pb:2, flexBasis: 'fit-content' }}>
                <Typography>Member since: {formatDate(stripeSubscription.created)}</Typography>
              </Grid>
              {/* <Divider light /> */}
              <Grid item xs={12} sx={{ pb:1 }}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ pb:1 }}>
                <Typography component="h3" variant="h5">Current Billing Cycle</Typography>
              </Grid>

              <Grid item xs={12} sx={{ pb:0 }}>
                <Typography>
                  Start: {formatDate(stripeSubscription.current_period_start)}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ pb:0 }}>
                <Typography>
                  Renewal: {formatDate(stripeSubscription.current_period_end)}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ pb:0 }}>
                <Typography>
                  Status: {stripeSubscription.status === 'trialing' ? 'Trial' : 'Active'}
                </Typography>
              </Grid>
              
              {stripeSubscription.canceled_at && (
                <Grid item xs={12} sx={{ pb:0 }}>
                  <Typography>
                    Canceled At: {formatDate(stripeSubscription.canceled_at)}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sx={{ pb:2, flexBasis: 'fit-content' }}>
                {!stripeSubscription.canceled_at && (
                  <Button sx={{ mt: 2, py: 2, color: '#fff' }}
                    // fullWidth
                    variant="contained" 
                    color="tertiary" 
                    // sx={{color: '#fff', backgroundColor: 'tertiary.main'}} 
                    onClick={handleCancelSubscription}
                    disabled={isCancelling}
                    >
                    {isCancelling ? <CircularProgress size={24} /> : 'Cancel Subscription'}
                  </Button>
                )}
              </Grid>

            </Grid>
          </Grid>
        ) : (                        
          <Typography>
            Loading subscription details...
          </Typography>
        )}
      </Box>
    </Container>
  )
}

const mapState = (state) => {
  if(debug) console.log("Mapping state to props", state)
  let currentUserSub = state.theUser.userSubs?.current

  return {
    // theUser: state.theUser,
    // userId: state.theUser.id,
    isUserSubActive: currentUserSub?.endDate ? (DateTime.fromISO(currentUserSub.endDate) > DateTime.now()) : false
  }
}

export default connect(mapState)(SubscriptionManager)
